import ReactDOM from 'react-dom/client';
import App from './App';
import companyName from './constants/companyNames';
import React from 'react';

const link = document.querySelector("link[rel~='icon']");
switch (companyName.short) {
  case "O'Neill":
    document.title = "O'Neill Portal";
    link.href = '/favicon.ico';
    break;
  case 'Sunshine':
    document.title = 'Sunshine Portal';
    link.href = '/faviconSunshine.ico';
    break;
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
