import {
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  TextField
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import AutocompleteInput from 'components/Autocomplete/AutocompleteInput';
import Button from 'components/Button/Button';
import { USER_TYPES } from 'constants/enum';
import icons from 'constants/icons';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAppointmentsViewData,
  getSelectedAgenciesAgents,
  getSpecificAgencyData,
  getStatementsPeriods
} from 'state/actions/actions';
import style from '../DashboardCustomerBob.module.scss';
import SimpleFilter from './SimpleFilter/SimpleFilter';

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  },
  color: '#656464'
}));

const FilterIcon = icons['filterList'];
const ExpandMoreIcon = icons['arrowDropDown'];

const isLOA = obj => {
  return false;
  const compLevel =
    obj['Custom_Comp_Level'] !== null
      ? obj['Custom_Comp_Level']
      : obj['Comp_LevelsX'];
  return compLevel === 'LOA';
};

const BobTableFilters = ({
  localLoader,
  filterCount,
  formData,
  clearFilters,
  handleChangeSelectValue,
  applyFilters,
  setformData,
  showFilters,
  carriersOptions
}) => {
  const dispatch = useDispatch();
  const cancelTokenSource = axios.CancelToken.source();

  // Local states
  const [, setAgentsLoaded] = useState(false);
  const [allAgenciesAreLoa, setAllAgenciesAreLoa] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const initialLoad = useRef(true);

  const { zohoData } = useSelector(state => state.user);

  const currentAgenciesAgentsDict = useSelector(
    state => state.agencyManagement.comissions_tab_agents
  );

  const { agencyInformation } = useSelector(
    state => state.agencyManagement.agencyData
  );

  const notAdminAndLOA = React.useMemo(
    () => zohoData.Position_at_Agency !== USER_TYPES.ADMIN && isLOA(zohoData),
    [zohoData]
  );

  const agenciesOptions = React.useMemo(() => {
    // If user is not admin, don't compute any of this
    if (zohoData.Position_at_Agency !== USER_TYPES.ADMIN) return [];

    // Preselects agency
    if (agencyInformation?.id?._value && agencyInformation?.name?._value) {
      setformData(prevState => ({
        ...prevState,
        ['agencies']: [
          {
            id: agencyInformation?.id?._value,
            name: agencyInformation?.name?._value
          }
        ]
      }));
    }
    console.log('agencyInformation.Downline_Agencies_Three');
    console.log(agencyInformation.Downline_Agencies_Three);
    const rawAgenciesOptions =
      agencyInformation.Downline_Agencies_Three?.concat([agencyInformation]);

    const filteredAgenciesOptions = rawAgenciesOptions;
    if (
      rawAgenciesOptions?.length !== 0 &&
      filteredAgenciesOptions?.length === 0
    ) {
      setAllAgenciesAreLoa(true);
    }
    const returnAgenciesOptions =
      filteredAgenciesOptions?.map(agency => ({
        id: agency.id._value,
        name: agency.name._value
      })) || [];

    // Gets all the agents from all the agencies from the tree
    if (zohoData.Position_at_Agency === USER_TYPES.ADMIN) {
      const ids = [...returnAgenciesOptions?.map(agency => agency.id)];
      dispatch(
        getSelectedAgenciesAgents(
          () => {},
          currentAgenciesAgentsDict,
          ids,
          cancelTokenSource
        )
      );
    }

    return returnAgenciesOptions;
  }, [agencyInformation.Downline_Agencies_Three]);

  const mainAgentsOptions = React.useMemo(() => {
    const mainOptions =
      zohoData.NPN !== null &&
      zohoData.NPN !== 0 &&
      zohoData.NPN !== '' &&
      zohoData.NPN !== '0' &&
      zohoData.NPN !== 1 &&
      zohoData.NPN !== '1' &&
      zohoData.NPN.toString().length > 4 &&
      zohoData.NPN.toString().length <= 9
        ? [
            {
              type: 'Main Agent',
              name: zohoData.First_Name + ' ' + zohoData.Last_Name,
              npn: zohoData.NPN,
              Agency_Name: 'Main Agent',
              Agency_Id: zohoData.Account_Name?.id,
              id: zohoData?.id,
              Position_at_Agency: zohoData.Position_at_Agency
            }
          ]
        : [];
    return mainOptions;
  }, [zohoData.NPN, zohoData.First_Name, zohoData.Last_Name, zohoData.id]);

  const agentsOptions = React.useMemo(() => {
    const selectedAgenciesAgents = Object.keys(currentAgenciesAgentsDict)
      .map(selected_agency_id => currentAgenciesAgentsDict[selected_agency_id])
      .filter(x => x)
      .flat()
      .filter(option => !isLOA(option))
      .map(option => {
        return {
          type: 'Downline Agent',
          name: option.First_Name + ' ' + option.Last_Name,
          npn: option.NPN,
          Agency_Id: option.Agency_Id,
          Agency_Name: option.Agency,
          id: option.id
        };
      })
      .filter(
        option =>
          option.npn !== null &&
          option.npn !== 0 &&
          option.npn !== '' &&
          option.npn !== '0' &&
          option.npn !== 1 &&
          option.npn !== '1' &&
          option.npn.toString().length > 4 &&
          option.npn.toString().length <= 9
      )
      .filter(option => option.npn !== zohoData.NPN);

    setAgentsLoaded(true);

    // If user is LOA, return only other agents
    if (isLOA(zohoData)) return selectedAgenciesAgents;

    // If user is not LOA, returns itself as well.
    return [...mainAgentsOptions, ...selectedAgenciesAgents];
  }, [currentAgenciesAgentsDict]);

  // Effects
  // Gets data from backend
  useEffect(() => {
    const getData = async () => {
      dispatch(
        getStatementsPeriods(
          () => {},
          () => {}
        )
      );
      dispatch(
        getAppointmentsViewData(
          () => {},
          () => {}
        )
      );
      if (zohoData.Position_at_Agency !== USER_TYPES.ADMIN) return;
      dispatch(
        getSpecificAgencyData(
          () => {},
          zohoData?.Account_Name?.id,
          () => {}
        )
      );
    };
    getData();
  }, []);

  // If all data from all sources has finished loading, turns off the local loader
  useEffect(() => {
    if (notAdminAndLOA)
      return () => {
        setAllLoaded(false);
      };
    const carriersOptionsAreLoaded = carriersOptions.length > 1;
    const agenciesOptionsAreLoaded =
      agenciesOptions.length > 0 ||
      allAgenciesAreLoa ||
      zohoData.Position_at_Agency !== USER_TYPES.ADMIN;
    const agentsOptionsAreLoaded = agentsOptions.length > 0 || legitHasNoAgents;

    console.log('-----------------');
    console.log(`carriersOptionsAreLoaded: ${carriersOptionsAreLoaded}`);
    console.log(`agentsOptionsAreLoaded: ${agentsOptionsAreLoaded}`);

    if (
      carriersOptionsAreLoaded &&
      agentsOptionsAreLoaded &&
      agenciesOptionsAreLoaded &&
      !allLoaded
    ) {
      setAllLoaded(true);
    }
  }, [carriersOptions, agentsOptions, agenciesOptions]);

  // Preselects main agent
  useEffect(() => {
    // Preselects the logged in agent if not LOA
    if (isLOA(zohoData)) {
      //nothing
    } else if (
      mainAgentsOptions.length > 0 &&
      allLoaded &&
      (mainAgentsOptions[0].Position_at_Agency !== 'Admin' ||
        (mainAgentsOptions[0].Position_at_Agency === 'Admin' &&
          agentsOptions.length > 1))
    ) {
      setformData(prevData => ({
        ...prevData,
        agents:
          mainAgentsOptions[0].Position_at_Agency === 'Admin'
            ? agentsOptions
            : mainAgentsOptions
      }));
    }
  }, [mainAgentsOptions, agentsOptions, allLoaded]);

  useEffect(() => {
    if (allLoaded && formData.agents.length > 0 && initialLoad.current) {
      applyFilters();
      initialLoad.current = false;
    }
  }, [formData.agents]);

  if (!showFilters) return null;

  return (
    <div className={style.filtersContainer}>
      <div className={style.filtersHeader}>
        <FilterIcon />
        <h4>Filters ({filterCount})</h4>
      </div>
      <div className={style.filters}>
        <div className={style.clearApplyFilters}>
          <Button
            text='Clear All'
            size='medium'
            type='secondary'
            onClick={() => clearFilters()}
          />
          <Button
            text='Apply Filters'
            size='medium'
            type='primaryDarkened'
            onClick={() => applyFilters()}
          />
        </div>
        <div
          style={{
            borderBottom: '1px solid #f6f6f6',
            width: '85%'
          }}
        />
        <Grid
          container
          columnSpacing={{ xs: 0, sm: 2, lg: 0 }}
          rowSpacing={{ xs: 2, sm: 2, md: 3 }}
          style={{
            width: '85%',
            marginTop: '20px',
            margin: '20px 0px'
          }}
        >
          {zohoData.Position_at_Agency === USER_TYPES.ADMIN && (
            <>
              <Grid item xs={12} sm={6} lg={12}>
                <AutocompleteInput
                  label={'agents'}
                  multiple
                  selectAll
                  loading={!allLoaded}
                  disabled={!allLoaded}
                  options={!allLoaded ? [] : agentsOptions}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  groupBy={option => option.Agency_Name}
                  getOptionLabel={option => option.name}
                  id='downlineagents'
                  value={!allLoaded ? [] : formData.agents}
                  onValueChanges={e => handleChangeSelectValue(e, 'agents')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={'Payee Agent(s)'}
                      placeholder={'Select agents'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {!allLoaded ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                  selectAllByGroup={true}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} lg={12}>
            <Accordion slotProps={{ heading: { component: 'h4' } }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='carrier-header'
                id='carrier-header'
              >
                Carrier
              </AccordionSummary>
              <AccordionDetails
                style={{
                  borderLeft: '5px solid #d0d0d1'
                }}
              >
                <AutocompleteInput
                  multiple
                  loading={localLoader}
                  options={carriersOptions
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .sort((a, b) => -b.type.localeCompare(a.type))}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  groupBy={option => option.type}
                  getOptionLabel={option => option.name}
                  id='carriers'
                  value={formData.carriers}
                  onValueChanges={e => handleChangeSelectValue(e, 'carriers')}
                  disabled={formData.includeAllCarriers}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Carrier(s)'
                      placeholder={'Select carriers'}
                      InputLabelProps={{
                        shrink: !formData.includeAllCarriers
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {localLoader ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={6} lg={12}>
            <Accordion slotProps={{ heading: { component: 'h4' } }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='bob-status-header'
                id='bob-status-header'
              >
                BOB Status
              </AccordionSummary>
              <AccordionDetails
                style={{
                  borderLeft: '5px solid #d0d0d1'
                }}
              >
                <SimpleFilter
                  options={['Active', 'Inactive']}
                  value={formData.activeFilter}
                  handleChange={event => {
                    const {
                      target: { value }
                    } = event;

                    handleChangeSelectValue(value, 'activeFilter');
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

BobTableFilters.propTypes = {
  localLoader: PropTypes.bool,
  filterCount: PropTypes.number,
  formData: PropTypes.object,
  clearFilters: PropTypes.func,
  handleChangeSelectValue: PropTypes.func,
  applyFilters: PropTypes.func,
  setformData: PropTypes.func,
  showFilters: PropTypes.bool,
  carriersOptions: PropTypes.array
};

export default memo(BobTableFilters);
