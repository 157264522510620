import { Stack } from '@mui/material';
import axios from 'axios';
import AutocompleteInput from 'components/Autocomplete/AutocompleteInput';
import Button from 'components/Button/Button';
import companyName from 'constants/companyNames';
import { STATE_LIST_CERT } from 'constants/enum';
import { arrayDifference } from 'helpers/arrays';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import { showAlertModal } from 'state/actions/site';
import { setRequestedCarrierName } from '../../../state/actions/appointments';
import StateBasedExchangeRequestModals from '../StateBasedExchangeRequestModals/StateBasedExchangeRequestModals';
import style from './sideSection.module.scss';

function SideSection({
  confirmed = [],
  pending = [],
  username,
  password,
  ONeillNote,
  isMarketplace,
  carrier,
  agentStatus,
  comments,
  carrierData,
  statesOffered,
  addRequestedAppointments,
  InitialOnboardingFormCompleted
}) {
  const cancelTokenSource = axios.CancelToken.source();
  //Redux
  const isSendingRequestAppointments = useSelector(
    state => state.appointments.isSendingRequestAppointments
  );
  const dispatch = useDispatch();

  const [formData, setFormData] = React.useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  // const user = useSelector(state => state.user.zohoData);
  // const handleOpenLink = () => {
  //   window.open(
  //     `https://forms.oneillmarketing.net/oneillmarketing/form/IndividualAppointmentRequestForm/formperma/cJer2y2EfRB6W8F6Al74YPryxKlkdl1kzBnw1hH1eik?npnField98221=${
  //       user.NPN_Text_Field
  //     }&ffm=${user.ffm_ms[0] && user.ffm_ms[0]}&ahip=${
  //       user.AHIP[0] && user.AHIP[0]
  //     }`,
  //     'blank'
  //   );
  // };

  const handleChangeAppointments = values => {
    setFormData(values);
  };

  const handleConfirmRequest = () => {
    // if InitialOnboardingFormCompleted
    if (InitialOnboardingFormCompleted) {
      // check if state requires certification
      const isRequiredStateIncluded = STATE_LIST_CERT.some(state =>
        formData.includes(state.state)
      );
      if (isRequiredStateIncluded) {
        setOpenConfirmationModal(true);
      } else {
        handleSendRequest(formData);
      }
    } else {
      dispatch(
        showAlertModal({
          title: 'Initial Onboarding Form Not Completed',
          message: `You must complete the Onboarding Form that was emailed to you and have it approved in order to be able to request appointments. Please search your inbox for the email with the subject: “O${"'"}Neill Marketing Onboarding Steps”, or reach out to us for assistance at Contracting@Oneillmarketing.net.`
        })
      );
    }
  };

  const handleSendNotRequiredStates = nonRequiredStates => {
    handleSendRequest(nonRequiredStates);
  };

  const handleSendRequest = async appointments => {
    setOpenConfirmationModal(false);
    setOpenInfoModal(false);
    const hasStates = appointments.length > 0 ? true : false;
    const data = {
      hasStates: !isMarketplace ? false : hasStates,
      carrierName: carrier,
      states: appointments,
      transfer: 'New Appointment',
      carrierData
    };
    dispatch(setRequestedCarrierName(carrier));
    await addRequestedAppointments(cancelTokenSource, data);
    setFormData([]);
  };

  const sanitize = dirty => ({
    __html: sanitizeHtml(dirty)
  });

  const renderNotesAndComments = () => {
    return (
      <>
        <div className={style.section}>
          {ONeillNote ? (
            <>
              <p className={style.title}>{`${companyName.short} Notes`}</p>
              <div className={style.note}>{ONeillNote}</div>
            </>
          ) : null}
        </div>

        {comments ? (
          <div className={style.section}>
            {carrierData?.customNoteAddStates && confirmed.length > 0 ? (
              <>
                <p className={style.title}>Additional States Note</p>
                <Stack sx={{ whiteSpace: 'break-spaces' }}>
                  <div
                    className={style.note}
                    dangerouslySetInnerHTML={sanitize(
                      carrierData.customNoteAddStates.replace(
                        /(https?:\/\/[^\s]+)/g,
                        '<a href="$1" target="_blank" >$1</a>'
                      )
                    )}
                  />
                </Stack>
              </>
            ) : null}
            <p className={style.title}>Comments</p>
            <Stack sx={{ whiteSpace: 'break-spaces' }}>
              <div
                className={style.note}
                dangerouslySetInnerHTML={sanitize(
                  comments.replace(
                    /(https?:\/\/[^\s]+)/g,
                    '<a href="$1" target="_blank" >$1</a>'
                  )
                )}
              />
            </Stack>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className={style.sideSection}>
      {isMarketplace ? (
        <>
          <div className={style.section}>
            <p className={style.title}>Confirmed</p>
            {confirmed.length > 0 ? (
              <div className={`${style.labels} ${style.confirmed}`}>
                {confirmed.map((confirmedState, i) => (
                  <span key={`${confirmedState}${i}`}>{confirmedState}</span>
                ))}
              </div>
            ) : (
              <div className={style.notfound}>
                <span>No confirmed appointments</span>
              </div>
            )}
          </div>

          {/* {(pending.length > 0 || (!isMarketplace && confirmed.length === 0)) && ( */}
          <div className={style.section}>
            <p className={style.title}>Pending</p>
            {pending.length > 0 ? (
              <div className={`${style.labels} ${style.pending}`}>
                {pending.map((pendingState, i) => (
                  <span key={`${pendingState}${i}`}>{pendingState}</span>
                ))}
              </div>
            ) : (
              isMarketplace && (
                <div className={style.notfound}>
                  <span>No pending appointments</span>
                </div>
              )
            )}
          </div>
          {/*pending.length > 0 && (
            <div className={style.section}>
              <p className={style.subtitle}>
                Did you complete contracting for this carrier?
              </p>
              <UpdateStatus carrier={carrier} agentStatus={agentStatus} />
            </div>
          )*/}
          {username && (
            <div className={style.section}>
              <p className={style.title}>Username</p>
              <span>{username}</span>
            </div>
          )}
          {password && (
            <div className={style.section}>
              <p className={style.title}>Password</p>
              <span>{password}</span>
            </div>
          )}
          {renderNotesAndComments()}
          <div className={style.section}>
            <p className={`${style.title} ${style.requestTitle}`}>
              Request new state appointments
            </p>
            <p className={style.subtitle}>
              Select states you want to request, then click {'"Send request"'}{' '}
              to request your appointments.
            </p>
            <Stack spacing={5} sx={{ width: '100%', mb: 2, mt: 2 }}>
              <AutocompleteInput
                multiple
                showScrollbar
                value={formData}
                label='Select or search states'
                placeholder='Search'
                options={arrayDifference(statesOffered, [
                  ...confirmed,
                  ...pending
                ])}
                onValueChanges={handleChangeAppointments}
              />
            </Stack>
            <Button
              disabled={
                formData.length < 1 || isSendingRequestAppointments
                  ? true
                  : false
              }
              isLoading={isSendingRequestAppointments}
              size='medium'
              type='primaryLight'
              text={'Send request'}
              rounded={'large'}
              onClick={handleConfirmRequest}
              fill
            />
          </div>
        </>
      ) : (
        <>
          <div className={style.section}>
            <h4>Status</h4>
            <p>
              {confirmed === true ? (
                <div className={`${style.labels} ${style.confirmed}`}>
                  <span>Confirmed</span>
                </div>
              ) : (
                <div className={`${style.labels} ${style.pending}`}>
                  <span>Pending</span>
                </div>
              )}
            </p>
          </div>
          {confirmed !== true && (
            <>
              {renderNotesAndComments()}
              <div className={style.section}></div>
            </>
          )}
        </>
      )}

      <StateBasedExchangeRequestModals
        appointments={formData}
        openConfirmationModal={openConfirmationModal}
        openInfoModal={openInfoModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setOpenInfoModal={setOpenInfoModal}
        onSendRequest={handleSendRequest}
        onSendNotRequiredStates={handleSendNotRequiredStates}
      />
      {/* )} */}
    </div>
  );
}

SideSection.propTypes = {
  carrier: propTypes.string,
  agentStatus: propTypes.string,
  username: propTypes.any,
  password: propTypes.any,
  ONeillNote: propTypes.string,
  comments: propTypes.string,
  confirmed: propTypes.array,
  pending: propTypes.array,
  carrierData: propTypes.object,
  statesOffered: propTypes.array,
  isMarketplace: propTypes.bool,
  addRequestedAppointments: propTypes.func,
  InitialOnboardingFormCompleted: propTypes.bool
};

export default SideSection;
