import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';

const SimpleFilter = ({
  multiple = true,
  showCount = true,
  options,
  value,
  handleChange
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '15px'
      }}
    >
      <FormControl sx={{ width: '100%' }}>
        <Select
          multiple={multiple}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={selected => selected.join(', ')}
          style={{
            color: '#656464'
          }}
        >
          {options.map(_value => (
            <MenuItem key={_value} value={_value}>
              <Checkbox checked={value.includes(_value)} />
              <ListItemText primary={_value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showCount && <span>({value.length})</span>}
    </div>
  );
};

SimpleFilter.propTypes = {
  options: PropTypes.array,
  handleChange: PropTypes.func,
  value: PropTypes.any,
  multiple: PropTypes.bool,
  showCount: PropTypes.bool
};

export default SimpleFilter;
