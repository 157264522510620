import React, { useEffect, useState } from 'react';
import style from './changePhoneForm.module.scss';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';
import useChangePhone from '../useChangePhone';
import { useDispatch } from 'react-redux';
import { REFRESH_USER, SHOW_ALERT } from 'constants/types';
import { getUser } from 'services/user';
import VerificationCode from 'views/MFA/CodeVerification/VerificationCode';
import PhoneNumberInput from 'views/MFA/PhoneNumberInput/PhoneNumberInput';

function ChangePhoneForm({
  userCredentials = null,
  setResolver = null,
  setUserCredentials = null,
  setVerificationId = null,
  resolver
}) {
  const [newVerificationId, setNewVerificationId] = useState('');
  const [newCredentials, setNewCredentials] = useState(null);
  const dispatch = useDispatch();

  const {
    loading,
    phoneChanged,
    phoneNumber,
    setPhoneNumber,
    changePhone,
    needsReauthentication
  } = useChangePhone();

  async function endFlow() {
    if (phoneChanged) {
      const userData = await getUser(newCredentials);
      console.log(userData);
      /* dispatch({
        type: REFRESH_USER,
        payload: { ...userData.data.zohoData, Portal_Password: newPassword }
      });
      dispatch({
        type: SHOW_ALERT,
        payload: {
          message: 'Password Updated Successfully',
          code: 'success'
        }
      }); */
    }
  }

  useEffect(() => {
    endFlow();
  }, [phoneChanged]);

  useEffect(() => {
    if (needsReauthentication) {
      setResolver(null);
      setUserCredentials(null);
      setVerificationId('');
      setPhoneNumber('');
    }
  }, [needsReauthentication]);

  return (
    <form className={style.genericForm}>
      {newVerificationId === '' ? (
        <PhoneNumberInput
          resolver={resolver}
          setVerificationId={setNewVerificationId}
          setPhoneNumber={setPhoneNumber}
          newPhone
        />
      ) : (
        <VerificationCode
          resolver={resolver}
          verificationId={newVerificationId}
          phoneNumber={phoneNumber}
          setUserCredentials={setNewCredentials}
          reloadPortal={false}
          newPhone
          newPhoneUpdate
        />
      )}
 {/*      <div className={style.buttons}>
        <Button
          isDisabled={!newCredentials}
          text={'Change Phone'}
          className={style.formButton}
          isLoading={loading}
          onClick={e => {
            e.preventDefault();
            changePhone(newCredentials);
          }}
        />
      </div> */}
    </form>
  );
}

ChangePhoneForm.propTypes = {
  userCredentials: PropTypes.object,
  setResolver: PropTypes.func,
  setUserCredentials: PropTypes.func,
  setVerificationId: PropTypes.func,
  resolver: PropTypes.object
};

export default ChangePhoneForm;
