import React, { useEffect } from 'react';
import style from './changePasswordForm.module.scss';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';
import useChangePassword from '../useChangePassword';
import { useDispatch } from 'react-redux';
import { REFRESH_USER, SHOW_ALERT } from 'constants/types';
import { getUser } from 'services/user';

function ChangePasswordForm({
  userCredentials = null,
  setResolver = null,
  setUserCredentials = null,
  setVerificationId = null,
  setPhoneNumber = null
}) {
  const dispatch = useDispatch();

  const {
    loading,
    errors,
    newPassword,
    repeatNewPassword,
    passwordChanged,
    setNewPassword,
    setRepeatNewPassword,
    changePassword,
    needsReauthentication
  } = useChangePassword();

  async function endFlow() {
    if (passwordChanged) {
      const userData = await getUser(userCredentials);
      dispatch({
        type: REFRESH_USER,
        payload: { ...userData.data.zohoData, Portal_Password: newPassword }
      });
      dispatch({
        type: SHOW_ALERT,
        payload: {
          message: 'Password Updated Successfully',
          code: 'success'
        }
      });
    }
  }

  useEffect(() => {
    endFlow();
  }, [passwordChanged]);

  useEffect(() => {
    if (needsReauthentication) {
      setResolver(null);
      setUserCredentials(null);
      setVerificationId('');
      setPhoneNumber('');
    }
  }, [needsReauthentication]);

  return (
    <form className={style.genericForm}>
      <Input
        name='newPassword'
        label='Password'
        type='password'
        error={errors.newPassword}
        placeholder='Enter your new password'
        onChange={e => setNewPassword(e.target.value)}
        value={newPassword}
      />
      <Input
        name='repeatNewPassword'
        label='Confirm Password'
        type='password'
        error={errors.repeatNewPassword}
        placeholder='Confirm Your Password'
        onChange={e => setRepeatNewPassword(e.target.value)}
        value={repeatNewPassword}
      />
      <div className={style.buttons}>
        <Button
          text={'change password'}
          className={style.formButton}
          isLoading={loading}
          onClick={e => {
            e.preventDefault();
            changePassword();
          }}
        />
      </div>
    </form>
  );
}

ChangePasswordForm.propTypes = {
  userCredentials: PropTypes.object,
  setResolver: PropTypes.func,
  setUserCredentials: PropTypes.func,
  setVerificationId: PropTypes.func,
  setPhoneNumber: PropTypes.func
};

export default ChangePasswordForm;
