import { useState } from 'react';
import firebase from 'firebase/compat/app';

function useReauthenticate() {
  
  const [error, setError] = useState('');
  const [userCredentials, setUserCredentials] = useState(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [resolver, setResolver] = useState(null);

  async function reauthenticate() {
    setError('');
    setLoading(true);
    try {
      const currentUser = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        password
      );
      const firebaseData = await currentUser.reauthenticateWithCredential(credential);
      setUserCredentials(firebaseData);
    } catch(e){
      if (e.code === 'auth/wrong-password') {
        setError('Wrong password');
      }
      if (e.code === 'auth/multi-factor-auth-required') {
        const resolver = e.resolver;
        setResolver(resolver);
      }
      else {
        setError('There was an error while validating your credentials');
      }
    }
    setLoading(false);
  }

  return {
    error,
    userCredentials,
    loading,
    password,
    resolver,
    setPassword,
    reauthenticate,
  };

}

export default useReauthenticate;