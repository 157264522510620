import React, { useEffect, useState } from 'react';
import CodeVerifier from '../CodeVerification/CodeVerifier';
import styles from './PhoneNumberInput.module.scss';
import images from 'constants/images';
import contryCodes from './contryCodes';
import Button from 'components/Button/Button';
import useCodeSender from '../useCodeSender';
import propTypes from 'prop-types';

const recaptchaContainer = 'recaptcha-container';

const PhoneNumberInput = ({
  resolver = null,
  setVerificationId,
  setPhoneNumber: setPhoneNumberProp,
  newPhone = false
}) => {
  const hints = resolver?.hints ?? [];

  const {
    error,
    sendCode,
    verificationId,
    phoneNumber,
    setPhoneNumber,
    isLoading
  } = useCodeSender(recaptchaContainer, resolver);

  console.log(resolver);
  const [code, setCode] = useState('+1');
  useEffect(() => {
    if (error === '' && verificationId !== '') {
      setVerificationId(verificationId);
      setPhoneNumberProp(phoneNumber);
    }
  }, [verificationId, phoneNumber]);

  return (
    <div className={newPhone ? {} : styles.login}>
      <form className={styles.formContainer}>
        {!newPhone && (
          <>
            <img src={images.brand.fullLogo} alt={"O'Neill Logo"} />
            <p className={styles.subTitle}>{"O'Neill Host Portal Login"} </p>
          </>
        )}
        {error === '' && hints.length === 0 ? (
          <p className={styles.description}>
            {newPhone
              ? 'Enter the new phone number for 2FA'
              : 'Please enter your phone number to register for 2FA'}
          </p>
        ) : (
          <p className={styles.error}>{error}</p>
        )}
        {hints.length === 0 ? (
          <>
            <select
              style={{ width: '100%', marginBottom: '5%' }}
              onChange={e => {
                const newCode = e.target.value;
                const newDialCode = newCode.split('^')[1];
                setCode(newDialCode);
                setPhoneNumber(newDialCode + phoneNumber);
              }}
              defaultValue={'US^+1'}
            >
              {contryCodes.map(({ code, name, dial_code }) => {
                return (
                  <option key={code} value={`${code}^${dial_code}`}>
                    {name} {dial_code}
                  </option>
                );
              })}
            </select>
            <CodeVerifier
              type='phone'
              length={10}
              getCode={input => setPhoneNumber(code + input)}
              isLoading={false}
            />
          </>
        ) : (
          <p>We will send a code to the number: {hints.at(-1).phoneNumber}</p>
        )}
        <div
          style={{ display: 'flex', marginTop: '5%', justifyContent: 'center' }}
        >
          <Button
            id={recaptchaContainer}
            type='primary'
            isLoading={isLoading}
            disabled={!resolver && phoneNumber.length < 10}
            text={resolver ? 'Send' : 'Send Verification Code'}
            onClick={e => {
              e.preventDefault();
              sendCode();
            }}
          />
        </div>
      </form>
    </div>
  );
};

PhoneNumberInput.propTypes = {
  resolver: propTypes.object,
  setVerificationId: propTypes.func,
  setPhoneNumber: propTypes.func,
  newPhone: propTypes.bool
};

export default PhoneNumberInput;
