import { ContentCopy } from '@mui/icons-material';
import {
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material';

import axios from 'axios';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import icons from 'constants/icons';
import useCustomersData from 'hooks/useCustomersData';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { emailedCarrierPolicy, ignorePolicy } from 'state/actions/healthSherpa';
import { showAlert } from 'state/actions/site';
import CustomersTable from './CustomersTable/CustomersTable';
import CustomersTableFilters from './CustomersTableFilters/CustomersTableFilters';
import style from './DashboardCustomer.module.scss';
import ApptActionModal from './Modals/ApptActionModal';
import PotentialMatchesModal from './Modals/PotentialMatchesModal';

const SearchIcon = icons['search'];
const ArrowRight = icons['arrowRight'];

const DashboardCustomers = ({
  setLocalLoader,
  getAppointmentsViewData,
  addRequestedAppointments,
  carriers,
  /*   appointedCarriers, */
  zohoUserData
}) => {
  const dispatch = useDispatch();

  // Local State
  const [showFilters, setShowFilters] = React.useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [loadingApptsData, setLoadingApptsData] = useState(true);
  const [bobActionModalData, setBobActionModalData] = useState({});
  const [activePanel, setActivePanel] = useState({});

  const [activeCarrier, setActiveCarrier] = useState({
    name: '',
    id: '',
    carrier: '',
    confirmed: [],
    pending: [],
    isMarketplace: false,
    statesOffered: []
  });

  const cancelTokenSource = axios.CancelToken.source();

  const {
    data_for_table,
    agent_policies_count,
    currentPage,
    setCurrentPage,
    setCurrentPageSize,
    formData,
    clearFilters,
    handleChangeSelectValue,
    applyFilters,
    setformData,
    setTriggerFetch,
    setPotentialMatchesData,
    potentialMatchesData,
    loadingPotentialMatches,
    deleteBobMatch,
    getPotentialMatches,
    confirmBobMatch
  } = useCustomersData({
    loadingApptsData,
    setLocalLoader,
    setFilterCount,
    setShowFilters,
    setActivePanel
  });

  useEffect(() => {
    getAppointmentsViewData(() => {}, cancelTokenSource, true);
    setLoadingApptsData(false);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      applyFilters();
    }, 800);

    return () => clearTimeout(timeoutId);
  }, [formData.searchName]);

  const handleCloseModalAppt = () =>
    setActiveCarrier({
      name: '',
      id: '',
      carrier: '',
      confirmed: [],
      pending: [],
      isMarketplace: false,
      statesOffered: []
    });

  const handleClosePotentialMatchesModal = () =>
    setPotentialMatchesData({
      HsPolicyId: '',
      currentBobMatchId: '',
      npn: '',
      BobStatus: '',
      potentialBobMatches: []
    });

  const handleCloseModalBob = () => setBobActionModalData({});

  const copyText = text => {
    navigator.clipboard.writeText(text);
    dispatch(showAlert({ message: 'Copied to clipboard!', code: 'success' }));
  };

  return (
    <>
      <ApptActionModal
        activeCarrier={activeCarrier}
        handleCloseModalAppt={handleCloseModalAppt}
        addRequestedAppointments={addRequestedAppointments}
        zohoUserData={zohoUserData}
        setLocalLoader={setLocalLoader}
        applyFilters={applyFilters}
      />
      <Modal
        active={bobActionModalData.id}
        title='BOB Action'
        footer={
          <div
            className={style.wrapButtonsSmall}
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '15px',
              justifyContent: 'space-between'
            }}
          >
            <Button
              fill
              type='secondary'
              text='Cancel'
              onClick={handleCloseModalBob}
            />
            <Button
              fill
              type={bobActionModalData.ignored ? 'primary' : 'danger'}
              isDisabled={bobActionModalData.status === 'EmailedCarrier'}
              text={
                bobActionModalData.ignored
                  ? 'Un-Ignore Policy'
                  : 'Ignore Policy'
              }
              onClick={() =>
                dispatch(
                  ignorePolicy(
                    function loader(val) {
                      setLocalLoader(val);
                      applyFilters();
                      handleCloseModalBob();
                    },
                    () => {},
                    bobActionModalData.id,
                    {
                      ignore_bob: true,
                      npn: bobActionModalData.AssistorNpn
                    },
                    bobActionModalData.ignored
                  )
                )
              }
            />
            <Button
              fill
              className={style.buttonNoWrap}
              isDisabled={
                !(
                  bobActionModalData.email !== 'Unavailable' &&
                  (bobActionModalData.status === 'PendingMatch' ||
                    bobActionModalData.status === 'AssignedToOther')
                )
              }
              type='primaryDarkened'
              text='Mark as Sent'
              onClick={() => {
                dispatch(
                  emailedCarrierPolicy(
                    function loader(val) {
                      setLocalLoader(val);
                      applyFilters();
                      handleCloseModalBob();
                    },
                    () => {},
                    {
                      policy_id: bobActionModalData.id,
                      carrier: bobActionModalData.issuer_name,
                      name: bobActionModalData.AssistorName.trim(),
                      npn: bobActionModalData.AssistorNpn,
                      carrierEmail: bobActionModalData.email
                    }
                  )
                );
              }}
            />
          </div>
        }
        closeModal={handleCloseModalBob}
      >
        <div
          style={{
            minHeight: '280px'
          }}
        >
          <div
            style={{
              borderTop: '2px solid #f6f6f6',
              marginBlock: '10px'
            }}
          />
          <InputLabel htmlFor='outlined-adornment-email'>
            Carrier Email:
          </InputLabel>
          <OutlinedInput
            id='outlined-adornment-email'
            value={bobActionModalData.email}
            sx={{
              width: '100%',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000'
              }
            }}
            disabled
            endAdornment={
              <InputAdornment position='start'>
                <Button
                  type='transparent'
                  onClick={() => {
                    copyText(bobActionModalData.email);
                  }}
                  icon={<ContentCopy fontSize='inherit' />}
                />
              </InputAdornment>
            }
            slotProps={{
              input: {
                readOnly: true
              }
            }}
          />
          <InputLabel
            htmlFor='outlined-adornment-message'
            style={{
              marginTop: '20px'
            }}
          >
            Message:
          </InputLabel>
          <OutlinedInput
            multiline
            id='outlined-adornment-message'
            value={bobActionModalData.message}
            sx={{
              width: '100%',
              fontSize: '12px',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000'
              }
            }}
            disabled
            rows={8}
            endAdornment={
              <InputAdornment position='start'>
                <Button
                  type='transparent'
                  onClick={() => copyText(bobActionModalData.message)}
                  icon={<ContentCopy fontSize='inherit' />}
                />
              </InputAdornment>
            }
            slotProps={{
              input: {
                readOnly: true
              }
            }}
          />
        </div>
      </Modal>
      <PotentialMatchesModal
        potentialMatchesData={potentialMatchesData}
        handleClosePotentialMatchesModal={handleClosePotentialMatchesModal}
        loadingPotentialMatches={loadingPotentialMatches}
        deleteBobMatch={deleteBobMatch}
        getPotentialMatches={getPotentialMatches}
        confirmBobMatch={confirmBobMatch}
      />
      <Grid
        container
        columnSpacing={{ xs: 1 }}
        rowSpacing={{ xs: 1 }}
        marginTop={2}
        paddingTop={1}
      >
        <Grid
          item
          xs={showFilters ? 12 : 0}
          lg={showFilters ? 4 : 0}
          xl={showFilters ? 3 : 0}
          className={style.filtersContainerMain}
        >
          <CustomersTableFilters
            filterCount={filterCount}
            setFilterCount={setFilterCount}
            formData={formData}
            setformData={setformData}
            clearFilters={clearFilters}
            handleChangeSelectValue={handleChangeSelectValue}
            applyFilters={applyFilters}
            showFilters={showFilters}
          />
        </Grid>
        <Grid item xs={12} lg={showFilters ? 8 : 12} xl={showFilters ? 9 : 12}>
          <div className={style.tableContainer}>
            <Grid container padding={2} rowSpacing={{ xs: 1 }}>
              <Grid item xs={12} sm={4}>
                <Button
                  text={`${
                    showFilters ? 'Hide Filters' : 'Show Filters'
                  } (${filterCount})`}
                  size='medium'
                  className={style.buttonNoWrap}
                  type='primaryDarkened'
                  icon={
                    <ArrowRight
                      className={
                        showFilters
                          ? style.arrowRightTransitionActive
                          : style.arrowRightTransition
                      }
                    />
                  }
                  onClick={() => setShowFilters(!showFilters)}
                />
              </Grid>
              <Grid item xs={12} sm={8} display='flex'>
                <TextField
                  fullWidth
                  size='small'
                  id='outlined-search'
                  label='Search for a client'
                  value={formData.searchName}
                  onChange={e => {
                    setformData(_formData => ({
                      ..._formData,
                      searchName: e.target.value
                    }));
                  }}
                  type='search'
                  InputProps={{
                    sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                  }}
                />
                <Button
                  text='Search'
                  type='primary'
                  size='medium'
                  className={style.searchButton}
                  onClick={() => applyFilters()}
                  icon={<SearchIcon />}
                />
              </Grid>
            </Grid>
            <CustomersTable
              data_for_table={data_for_table}
              agent_policies_count={agent_policies_count}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setCurrentPageSize={setCurrentPageSize}
              formData={formData}
              setActiveCarrier={setActiveCarrier}
              activeCarrier={activeCarrier}
              carriers={carriers}
              zohoUserData={zohoUserData}
              setBobActionModalData={setBobActionModalData}
              setTriggerFetch={setTriggerFetch}
              setPotentialMatchesData={setPotentialMatchesData}
              getPotentialMatches={getPotentialMatches}
              activePanel={activePanel}
              setActivePanel={setActivePanel}
              handleChangeSelectValue={handleChangeSelectValue}
              applyFilters={applyFilters}
              setLocalLoader={setLocalLoader}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

DashboardCustomers.propTypes = {
  setLocalLoader: PropTypes.func,
  getAppointmentsViewData: PropTypes.func,
  addRequestedAppointments: PropTypes.func,
  carriers: PropTypes.object,
  appointedCarriers: PropTypes.object,
  zohoUserData: PropTypes.object
};

export default DashboardCustomers;
