import { useState } from 'react';
import firebase from 'firebase/compat/app';

function useChangePhone() {
  const [loading, setLoading] = useState(false);
  const [phoneChanged, setPhoneChanged] = useState(false);
  const [needsReauthentication, setNeedsReauthentication] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  async function changePhone(userCredentials) {
    if (phoneNumber === '') return;

    setLoading(true);
    try {
      
/*       await firebase.auth().updateCurrentUser({
        multiFactor: {
          enrolledFactors: 
        }
      }) */
      setPhoneChanged(true);
    } catch (e) {
      console.log(e);
      if (e.code === 'auth/weak-password') {
      } else if (e.code === 'auth/requires-recent-login') {
        //setNeedsReauthentication(true);
      } else {
        console.log(e);
      }
    }
    setLoading(false);
  }

  return {
    loading,
    phoneNumber,
    setPhoneNumber,
    phoneChanged,
    changePhone,
    needsReauthentication
  };
}

export default useChangePhone;
