import React from 'react';
import propTypes from 'prop-types';
import LoginFormContainer from 'components/LoginFormContainer/LoginFormContainer';
import ReauthenticateForm from './ReauthenticateForm/ReauthenticateForm';

function Reauthenticate({ setResolver, setUserCredentials }) {
  return (
    <LoginFormContainer>
      <ReauthenticateForm setResolver={setResolver} setUserCredentials={setUserCredentials} />      
    </LoginFormContainer>
  );
}

Reauthenticate.propTypes = {
  setResolver: propTypes.func,
  setUserCredentials: propTypes.func,
};

export default Reauthenticate;
