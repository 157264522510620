import endpoints from 'constants/endpoints';
import {
  IS_SENDING_ONBOARDING_FORM,
  IS_UPDATING_PREFERENCES,
  RESET_AGENCY_DASHBOARD_AGENTS_DATA,
  RESET_AGENCY_DATA,
  RESET_MULTIPLE_AGENCIES_DATA,
  SET_AGENCIES_TREE,
  SET_AGENCY_DASHBOARD_AGENTS_DATA,
  SET_AGENCY_DATA,
  SET_AGENTS_APPOINTMENTS_COUNT,
  SET_COMISSIONS_TAB_AGENTS,
  SET_MULTIPLE_AGENCIES_DATA
} from 'constants/types';
// import { formatAgencies } from 'helpers/agencyManagement/formatAgencies';
import { safeAWSCallGet, safePostCall } from 'helpers/firebaseSetup';
import { removeBooleanStrings } from 'helpers/strings';
import { showAlert } from './site';

// action creators
export const setComissionsTabAgents = payload => {
  return {
    type: SET_COMISSIONS_TAB_AGENTS,
    payload
  };
};
export const setNewAgentsAppointmentsCount = payload => {
  return {
    type: SET_AGENTS_APPOINTMENTS_COUNT,
    payload
  };
};
export const setAgencyDashboardAgentsData = payload => {
  return {
    type: SET_AGENCY_DASHBOARD_AGENTS_DATA,
    payload
  };
};
export const resetAgencyDashboardAgentsData = payload => {
  return {
    type: RESET_AGENCY_DASHBOARD_AGENTS_DATA,
    payload
  };
};
export const setMultipleAgenciesData = payload => {
  return {
    type: SET_MULTIPLE_AGENCIES_DATA,
    payload
  };
};
export const resetMultipleAgenciesData = payload => {
  return {
    type: RESET_MULTIPLE_AGENCIES_DATA,
    payload
  };
};
export const setAgencyData = payload => {
  return {
    type: SET_AGENCY_DATA,
    payload
  };
};

export const resetAgencyData = payload => {
  return {
    type: RESET_AGENCY_DATA,
    payload
  };
};

export const setAgenciesTree = payload => {
  return {
    type: SET_AGENCIES_TREE,
    payload
  };
};

export const isUpdatingPreferences = payload => {
  return {
    type: IS_UPDATING_PREFERENCES,
    payload
  };
};

export const isSendingOnboardingForm = payload => {
  return {
    type: IS_SENDING_ONBOARDING_FORM,
    payload
  };
};

export const getAgenciesTree =
  (setLocalLoader = () => {}) =>
  async (dispatch, getState) => {
    setLocalLoader(true);
    const MainAgencyID = getState().user.zohoData.Account_Name.id;
    await safeAWSCallGet(
      endpoints.aws.getAgencyTree,
      {
        agency: MainAgencyID
      },
      false
    )
      .then(res => {
        const tree = [res.data];
        //Refresh redux state with the updated note
        dispatch(setAgenciesTree(tree));
        setLocalLoader(false);
      })
      .catch(error => {
        console.log(error);
        setLocalLoader(false);
      });
  };

export const downloadAppointmentsReport =
  (setLocalLoader = () => {}, agencyIds) =>
  async () => {
    setLocalLoader(true);
    await safeAWSCallGet(
      endpoints.aws.getAppointmentsReport,
      {
        agencies: agencyIds.join(',')
      },
      false
    )
      .then(response => {
        const build_csv_string = response.data.file.file.replaceAll(
          'JUMPLINE',
          '\n'
        );
        const url = window.URL.createObjectURL(new Blob([build_csv_string]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
        setLocalLoader(false);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setLocalLoader(false);
      });
  };

export const getSpecificAgencyData =
  (setLocalLoader, AgencyID) => async dispatch => {
    try {
      setLocalLoader(true);
      await dispatch(resetAgencyData());
      await dispatch(resetAgencyDashboardAgentsData());
      // Calls agency and agents from agency in parallel
      await Promise.all([
        safeAWSCallGet(
          endpoints.aws.selectedAgencies,
          {
            agencies: AgencyID
          },
          false
        ),
        safeAWSCallGet(
          endpoints.aws.selectedAgenciesAgents,
          {
            agencies: AgencyID
          },
          false
        )
      ]).then(async values => {
        const agencyDataResponseData = values[0].data[0];
        removeBooleanStrings(agencyDataResponseData);
        const memberAgentsResponseData = values[1].data;
        // Calls the appointments count endpoint
        const params = memberAgentsResponseData.map(a => a.id).join(',');
        safeAWSCallGet(
          endpoints.aws.getAppointmentsCount,
          {
            agents: params
          },
          false
        ).then(async res => {
          // Updates redux
          await Promise.all([
            dispatch(
              setAgencyData({
                agencyInformation: agencyDataResponseData,
                agents_per_agency: memberAgentsResponseData
              })
            ),
            dispatch(setAgencyDashboardAgentsData(memberAgentsResponseData)),
            dispatch(setNewAgentsAppointmentsCount(res.data.total))
          ]);
          // Finish
          setLocalLoader(false);
        });
      });
    } catch (e) {
      console.log('getSpecificAgencyData failed');
      console.log(e);
      setLocalLoader(false);
    }
  };

export const getSelectedAgenciesAgentsAndAppointmentCount =
  (setLocalLoader, AgencyIDs) => async dispatch => {
    setLocalLoader(true);
    await dispatch(resetAgencyDashboardAgentsData());
    // falta el reset del count
    const agenciesParams = AgencyIDs.join(',');
    await safeAWSCallGet(
      endpoints.aws.selectedAgenciesAgents,
      {
        agencies: agenciesParams
      },
      false
    ).then(agentsRes => {
      dispatch(setAgencyDashboardAgentsData(agentsRes.data));
      // Gets the count of appointments
      const agentsParams = agentsRes.data.map(a => a.id).join(',');
      safeAWSCallGet(
        endpoints.aws.getAppointmentsCount,
        {
          agents: agentsParams
        },
        false
      ).then(res => {
        dispatch(setNewAgentsAppointmentsCount(res.data.total));
        setLocalLoader(false);
      });
    });
  };

export const getSelectedAgenciesAgents =
  (setLocalLoader, currentDict, RequestedAgencyIds) => async dispatch => {
    setLocalLoader(true);
    // Figure out which agencies havent been gotten yet
    const current_ids = Object.keys(currentDict);
    const needed_ids = RequestedAgencyIds.filter(
      agency_id => !current_ids.includes(agency_id)
    );
    if (needed_ids.length > 0) {
      // falta el reset del count
      const agenciesParams = needed_ids.join(',');
      await safeAWSCallGet(
        endpoints.aws.selectedAgenciesAgents,
        { agencies: agenciesParams },
        false
      )
        .then(res => {
          let new_agencies_dict = {};
          for (let i = 0; i < needed_ids.length; i++) {
            const agency_id = needed_ids[i];
            new_agencies_dict[agency_id] = [];
          }
          for (let i = 0; i < res.data.length; i++) {
            const agent = res.data[i];
            const Agency_Id = agent.Agency_Id;
            const prevAgentList = new_agencies_dict[Agency_Id];
            const newAgentlist = [agent, ...prevAgentList];
            new_agencies_dict[Agency_Id] = newAgentlist;
          }
          const full_agencies_dict = {
            ...currentDict,
            ...new_agencies_dict
          };
          dispatch(setComissionsTabAgents(full_agencies_dict));
        })
        .catch(e => {
          console.log(`getSelectedAgenciesAgents error: ${e}`);
        });
    }
    setLocalLoader(false);
  };

export const UpdateAgencyFormPreferences =
  preferences => (dispatch, getState) => {
    let ZohoID = getState().agencyManagement.agencyData.agencyInformation.id;
    dispatch(isUpdatingPreferences(true));
    dispatch(showAlert({ message: 'Updating Preferences', code: 'info' }));
    // ----------------
    const queryForAWSdb = {
      agency: ZohoID._value,
      ...preferences
    };
    queryForAWSdb['Hide_Long_term_Health'] = queryForAWSdb['Hide_Long'];
    delete queryForAWSdb.Hide_Long;
    queryForAWSdb['Hide_Off_Market'] = queryForAWSdb['Hide_OffMarket'];
    delete queryForAWSdb.Hide_OffMarket;

    // -------------------
    Promise.all([
      safePostCall(endpoints.awsProxy.safePost, {
        ...preferences,
        ZohoID,
        endpoint: endpoints.zoho.updateAgencyFormPreferences
      }),
      safeAWSCallGet(endpoints.aws.saveAgencyFields, queryForAWSdb, false)
    ])
      .then(() => {
        //Refresh redux state with the updated note
        dispatch(isUpdatingPreferences(false));
        dispatch(
          showAlert({ message: 'Preferences updated', code: 'success' })
        );
      })
      .catch(error => {
        console.log(error);
        dispatch(isUpdatingPreferences(false));
      });
  };

export const SendEditAgencyInfoFormToAgent = () => (dispatch, getState) => {
  let agentEmail = getState().user.zohoData.Email;
  let agencyID = getState().agencyManagement.agencyData.agencyInformation.id;
  dispatch(showAlert({ message: 'Sending Email', code: 'info' }));
  safePostCall(endpoints.awsProxy.safePost, {
    endpoint: endpoints.zoho.sendEditAgencyInfoFormToAgent,
    agencyID,
    agentEmail: agentEmail
  })
    .then(() => {
      dispatch(
        showAlert({ message: 'Email sent successfully!', code: 'success' })
      );
    })
    .catch(error => console.log(error));
};

export const SendAgencyFormEmail =
  (Emails, typeOfForm, FirstName = '', LastName = '', NPN = '') =>
  async (dispatch, getState) => {
    let AgencyID = getState().agencyManagement.agencyData.agencyInformation.id;
    let AgentID = getState().user.zohoId;
    let AgencyHealthSherpa = getState().agencyManagement.agencyData
      .agencyInformation.Healthsherpa_Agency_Code
      ? getState().agencyManagement.agencyData.agencyInformation
          .Healthsherpa_Agency_Code
      : null;
    const message = Emails.length > 1 ? 'Emails' : 'Email';
    dispatch(isSendingOnboardingForm(true));
    dispatch(showAlert({ message: `Sending ${message}`, code: 'info' }));

    const logEmailSendAttempt = type => {
      const zohoEndpointUrl = endpoints.zoho.logOnboardingEmails;
      const logCallParams = {
        Emails,
        AgencyID: AgencyID._value,
        typeOfForm,
        NPN,
        FirstName,
        LastName,
        AgentID,
        type
      };
      safePostCall(endpoints.awsProxy.safePost, {
        endpoint: zohoEndpointUrl,
        ...logCallParams
      });
    };

    await safePostCall(endpoints.awsProxy.safePost, {
      endpoint: endpoints.zoho.sendAgencyFormEmail,
      AgencyID,
      Emails,
      typeOfForm,
      AgencyHealthSherpa
    })
      .then(() => {
        logEmailSendAttempt('success');
        dispatch(
          showAlert({
            message: ` ${message} sent successfully!`,
            code: 'success'
          })
        );
        dispatch(isSendingOnboardingForm(false));
      })
      .catch(async () => {
        showAlert({
          message: 'Something went wrong. Try again later.',
          code: 'error'
        });
        dispatch(isSendingOnboardingForm(false));
      });
  };
