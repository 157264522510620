import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { carrierInProgress, ignoreCarrier } from 'state/actions/healthSherpa';
import HiddenSideSection from 'views/MyAppointments/HiddenSideSection/HiddenSideSection';
import SideSection from 'views/MyAppointments/SideSection/SideSection';
import SideSectionUnnapointed from 'views/MyAppointments/SideSectionUnnapointed/SideSectionUnnapointed';
import style from '../DashboardCustomer.module.scss';

const ApptActionModal = ({
  activeCarrier,
  handleCloseModalAppt,
  addRequestedAppointments,
  zohoUserData,
  setLocalLoader,
  applyFilters
}) => {
  const dispatch = useDispatch();
  const sideSectionRef = React.useRef();
  const sideSectionUnAppRef = React.useRef();

  const [apptRequestStatus, setApptRequestStatus] = React.useState({
    appointments: [],
    loading: false,
    disabled: false
  });

  useEffect(() => {
    if (!activeCarrier.id) {
      setCurrentStep(0);
    }
  }, [activeCarrier.id]);

  const [currentStep, setCurrentStep] = React.useState(0);

  const putCarrierInProgress = React.useCallback((id, appointments) => {
    setLocalLoader(true);
    dispatch(
      carrierInProgress(
        function loader(val) {
          setLocalLoader(val);
          applyFilters();
          handleCloseModalAppt();
        },
        () => {},
        id,
        appointments
      )
    );
  }, []);

  const unApp =
    !activeCarrier.name ||
    (activeCarrier.confirmed.length === 0 &&
      activeCarrier.pending.length === 0 &&
      activeCarrier.createdDate === '');

  return (
    <Modal
      active={activeCarrier.id}
      title={
        currentStep === 0
          ? `Request Appointment for ${activeCarrier.name}?`
          : 'Warning!!!'
      }
      footer={
        <div
          className={style.wrapButtonsSmall}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
            justifyContent: 'space-between'
          }}
        >
          <Button
            fill
            type='secondary'
            text='Cancel'
            onClick={handleCloseModalAppt}
          />
          <Button
            fill
            className={style.buttonNoWrap}
            type={activeCarrier.ignored ? 'primary' : 'danger'}
            text={
              activeCarrier.ignored ? 'Un-Ignore Carrier' : 'Ignore Carrier'
            }
            onClick={() => {
              if (currentStep === 0 && !activeCarrier.ignored) {
                setCurrentStep(1);
                return;
              }
              if (!activeCarrier.id) return;

              dispatch(
                ignoreCarrier(
                  function loader(val) {
                    setLocalLoader(val);
                    applyFilters();
                    handleCloseModalAppt();
                  },
                  () => {},
                  activeCarrier.id,
                  {
                    ignore_appt: true
                  },
                  activeCarrier.ignored
                )
              );
            }}
          />
          {currentStep === 0 && (
            <Button
              fill
              className={style.buttonNoWrap}
              type='primaryDarkened'
              text='Send Request Now'
              isDisabled={apptRequestStatus.disabled || activeCarrier.ignored}
              isLoading={apptRequestStatus.loading}
              onClick={() =>
                unApp
                  ? sideSectionUnAppRef.current.handleConfirmRequest()
                  : sideSectionRef.current.handleConfirmRequest()
              }
            />
          )}
        </div>
      }
      closeModal={handleCloseModalAppt}
    >
      <div
        style={{
          minHeight: '280px'
        }}
      >
        <div
          style={{
            borderTop: '2px solid #f6f6f6',
            marginBlock: '10px'
          }}
        />
        {currentStep === 0 ? (
          unApp ? (
            activeCarrier.name ? (
              <SideSectionUnnapointed
                {...activeCarrier}
                ref={sideSectionUnAppRef}
                addRequestedAppointments={addRequestedAppointments}
                InitialOnboardingFormCompleted={
                  zohoUserData.Initial_Onboarding_Form_Completed
                }
                simplified
                setApptRequestStatus={setApptRequestStatus}
                carrierInProgress={putCarrierInProgress}
              />
            ) : (
              <HiddenSideSection text='Click on any pending carriers (in yellow color) to view more information on your appointment status and progress notes for that carrier.' />
            )
          ) : (
            <SideSection
              {...activeCarrier}
              ref={sideSectionRef}
              addRequestedAppointments={addRequestedAppointments}
              InitialOnboardingFormCompleted={
                zohoUserData.Initial_Onboarding_Form_Completed
              }
              simplified
              setApptRequestStatus={setApptRequestStatus}
              carrierInProgress={putCarrierInProgress}
            />
          )
        ) : (
          <div>
            <h2
              style={{
                color: 'red'
              }}
            >
              Be reminded that this option should only be selected if you are
              appointed with the carrier outside of OM, as you will not receive
              commissions for unappointed carriers.
            </h2>
          </div>
        )}
      </div>
    </Modal>
  );
};

ApptActionModal.propTypes = {
  activeCarrier: PropTypes.object,
  handleCloseModalAppt: PropTypes.func,
  addRequestedAppointments: PropTypes.func,
  zohoUserData: PropTypes.object,
  setLocalLoader: PropTypes.func,
  applyFilters: PropTypes.func
};

export default ApptActionModal;
