import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Button from 'components/Button/Button';
import { apptStatus, bobStatus } from 'constants/enum';
import icons from 'constants/icons';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadAllCustomersData,
  revalidatePolicy
} from 'state/actions/healthSherpa';
import { formatMoney } from 'utils/utils';
import style from '../DashboardCustomer.module.scss';
import HeaderFilterToolTip from '../HeaderFilter/HeaderFilterToolTip';

const showMoreKeys = [
  {
    key: 'Plan',
    label: 'Plan',
    style: {
      width: '200px'
    }
  },
  {
    key: 'Gross',
    label: 'Gross',
    isCurrency: true
  },
  {
    key: 'Net',
    label: 'Net',
    isCurrency: true
  },
  {
    key: 'Residence',
    label: 'Residence',
    style: {
      width: '200px'
    }
  },
  {
    key: 'AssistorName',
    label: 'Agent Name'
  },
  {
    key: 'AssistorAgency',
    label: 'Agency'
  }
];

const showDateKeys = [
  {
    key: 'Created',
    label: 'Submission'
  }
];

const CustomersTable = ({
  data_for_table,
  agent_policies_count,
  setCurrentPageSize,
  setCurrentPage,
  currentPage,
  formData,
  activeCarrier,
  setActiveCarrier,
  carriers,
  zohoUserData,
  setBobActionModalData,
  setTriggerFetch,
  setPotentialMatchesData,
  getPotentialMatches,
  activePanel,
  setActivePanel,
  handleChangeSelectValue,
  applyFilters,
  setLocalLoader
}) => {
  const dispatch = useDispatch();

  const { carriersArray } = useSelector(state => state.appointments);
  const { isDownloadingReport } = useSelector(state => state.commissions);

  const marketplaceSortedKeys = React.useMemo(
    () => Object.keys(carriers?.Marketplace ?? {}).sort(),
    [carriers?.Marketplace]
  );

  const reqAppointments = useSelector(
    state => state.appointments.requestedAppointments
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50
  });

  useEffect(() => {
    if (currentPage - 1 !== pagination.pageIndex) {
      setPagination(current => {
        return {
          ...current,
          pageIndex: currentPage - 1
        };
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage - 1 !== pagination.pageIndex) {
      setTriggerFetch(current => current + 1);
      setCurrentPage(pagination.pageIndex + 1);
    }
    setActivePanel({});
  }, [pagination.pageIndex]);

  useEffect(() => {
    setTriggerFetch(current => current + 1);
    setCurrentPageSize(pagination.pageSize);
    setActivePanel({});
  }, [pagination.pageSize]);

  useEffect(() => {
    if (activeCarrier.name)
      onMarketplaceClick([activeCarrier], activeCarrier.ignored);
  }, [reqAppointments]);

  const revalidateHSPolicy = useCallback((HsPolicyId, carrierName) => {
    setLocalLoader(true);
    dispatch(
      revalidatePolicy(
        function loader() {
          setTriggerFetch(current => current + 1);
        },
        () => {},
        HsPolicyId,
        {
          carrierName
        }
      )
    );
  }, []);

  const onMarketplaceClick = useCallback(
    (carrierData, ignored) => {
      if (!carrierData) return;

      const name = carrierData[0].name;

      let pending = reqAppointments[name]
        ? reqAppointments[name].carrier === 'BCBS'
          ? reqAppointments[name].bcbsState
          : reqAppointments[name].inProgressArray
        : [];
      let confirmed = zohoUserData[name.split(' ').join('_')] || [];
      let createdDate = reqAppointments[name]
        ? reqAppointments[name].created_time
        : '';
      let inProgressDate = reqAppointments[name]
        ? reqAppointments[name].in_progress_date
        : '';
      let agentStatus = reqAppointments[name]
        ? reqAppointments[name].agent_status
        : '';
      let username = reqAppointments[name]
        ? reqAppointments[name].username
        : '';
      let password = reqAppointments[name]
        ? reqAppointments[name].password
        : '';
      let ONeillNote = reqAppointments[name]
        ? reqAppointments[name].standard_note_for_agent
        : '';
      let comments = reqAppointments[name]
        ? reqAppointments[name].custom_note_for_agent
        : '';

      setActiveCarrier({
        ...carrierData[0],
        name,
        pending,
        confirmed,
        carrier: name,
        createdDate,
        inProgressDate,
        agentStatus,
        username,
        password,
        ONeillNote,
        comments,
        carrierData: carrierData[0],
        statesOffered: carrierData[0].statesOffered,
        isMarketplace: marketplaceSortedKeys.includes(name),
        ignored
      });
    },
    [marketplaceSortedKeys, reqAppointments, zohoUserData]
  );
  const columns = useMemo(
    () => [
      {
        header: 'Client',
        accessorKey: 'Client',
        size: 140,
        enableColumnFilter: false,
        flexGrow: 1,
        /* eslint react/prop-types: 0 */
        Cell: ({ cell }) => (
          <div
            style={{
              color: '#2564B3'
            }}
          >
            {cell.getValue()}
          </div>
        )
      },
      {
        header: 'Carrier',
        accessorKey: 'Carrier',
        size: 120,
        enableColumnFilter: false,
        flexGrow: 1
      },
      {
        header: 'Carrier Policy #',
        accessorKey: 'CarrierPolicyNumber',
        size: 150,
        enableColumnFilter: false
      },
      {
        header: 'State',
        accessorKey: 'State',
        size: 70,
        enableColumnFilter: false,
        enableResizing: false
      },
      {
        header: 'NPN',
        accessorKey: 'AssistorNpn',
        size: 90,
        enableResizing: false,
        enableColumnFilter: false
      },
      {
        header: 'Effective',
        accessorKey: 'Effective',
        enableResizing: false,
        size: 100,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const date = cell.getValue()?.split('T')[0]?.split('-') ?? '';
          const year = date[0],
            month = date[1],
            dt = date[2];

          return (
            <div>
              {year > 2100 ? 'N/A' : date ? `${month}-${dt}-${year}` : ''}
            </div>
          );
        }
      },
      {
        header: 'Carrier Appt Status',
        accessorKey: 'CarrierApptStatus',
        size: 290,
        flexGrow: 1,
        enableResizing: false,
        Filter: ({}) => {
          const options = ['Appointed', 'Not Appointed', 'Ignored', 'N/A'];

          return (
            <HeaderFilterToolTip
              applyFilters={applyFilters}
              clearFilters={() =>
                formData.carrierApptStatus.length > 0
                  ? handleChangeSelectValue(
                      formData.carrierApptStatus[0],
                      'carrierApptStatus'
                    )
                  : null
              }
              selectedTag={
                <CustomCells
                  Small
                  Value={apptStatus[formData.carrierApptStatus[0]]}
                  Column='CarrierApptStatus'
                />
              }
            >
              <div
                style={{
                  minWidth: '200px',
                  padding: '16px 16px 0px 16px'
                }}
              >
                <FormGroup>
                  {options.map(option => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={formData.carrierApptStatus[0] === option}
                        />
                      }
                      label={option}
                      onChange={() => {
                        handleChangeSelectValue(option, 'carrierApptStatus');
                      }}
                    />
                  ))}
                </FormGroup>
              </div>
            </HeaderFilterToolTip>
          );
        },
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                flexDirection: 'row',
                width: '100%'
              }}
            >
              <CustomCells
                Value={cell.row.original.incomplete ? 'N/A' : cell.getValue()}
                Column='CarrierApptStatus'
              />
              {!cell.row.original.incomplete &&
                (cell.row.original.carrier_audit_status === 'NotAppointed' ||
                  cell.row.original.carrier_audit_status === 'Ignore') && (
                  <Button
                    isLoading={carriersArray.length === 0}
                    text={
                      cell.row.original.incomplete ||
                      cell.row.original.carrier_audit_status === 'N/A'
                        ? 'N/A'
                        : 'Take Action'
                    }
                    size='small'
                    className={style.buttonNoWrap}
                    type='primaryDarkened'
                    isDisabled={
                      cell.row.original.CarrierApptStatus === 'Appointed' ||
                      cell.row.original.incomplete ||
                      cell.row.original.carrier_audit_status === 'N/A'
                    }
                    onClick={() => {
                      const carrierData = carriersArray.filter(
                        carrierdata =>
                          carrierdata.id === cell.row.original.CarrierId
                      );

                      if (!carrierData.length > 0) return;

                      onMarketplaceClick(
                        carrierData,
                        cell.row.original.CarrierApptStatus === 'Ignore'
                      );
                    }}
                  />
                )}
            </div>
          );
        }
      },
      {
        header: 'BOB Status',
        accessorKey: 'BobStatus',
        enableResizing: false,
        size: 240,
        flexGrow: 1,
        Filter: ({}) => {
          const options = [
            'Matched',
            '>2 Weeks Past',
            'Pending Match',
            'Ignored',
            'Check AOR',
            'Manually Unmatched',
            'Manually Matched'
          ];

          return (
            <HeaderFilterToolTip
              applyFilters={applyFilters}
              clearFilters={() =>
                formData.bobFilter.length > 0
                  ? handleChangeSelectValue(formData.bobFilter[0], 'bobFilter')
                  : null
              }
              selectedTag={
                <CustomCells
                  Small
                  Value={bobStatus[formData.bobFilter[0]]}
                  Column='BobStatus'
                />
              }
            >
              <div
                style={{
                  minWidth: '200px',
                  padding: '16px 16px 0px 16px'
                }}
              >
                <FormGroup>
                  {options.map(option => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox checked={formData.bobFilter[0] === option} />
                      }
                      label={option}
                      onChange={() => {
                        handleChangeSelectValue(option, 'bobFilter');
                      }}
                    />
                  ))}
                </FormGroup>
              </div>
            </HeaderFilterToolTip>
          );
        },
        Cell: ({ cell }) => {
          const date =
            (
              cell.row.original.appt_status_updated_at ??
              cell.row.original.created_at
            )
              ?.split('T')[0]
              ?.split('-') ?? '';
          const year = date[0],
            month = date[1],
            dt = date[2];

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <CustomCells Value={cell.getValue()} Column='BobStatus' />
                {!cell.row.original.incomplete && (
                  <Button
                    text={cell.row.original.incomplete ? 'N/A' : 'BOB Action'}
                    size='small'
                    className={style.buttonNoWrap}
                    type='primaryDarkened'
                    isDisabled={cell.row.original.incomplete}
                    onClick={() => {
                      const carrierData = carriersArray.filter(
                        carrierdata =>
                          carrierdata.id === cell.row.original.CarrierId
                      );

                      if (!carrierData.length > 0) return;

                      setBobActionModalData({
                        ...carrierData[0],
                        ...cell.row.original,
                        id: cell.row.original.HealthSherpaId,
                        email:
                          carrierData[0]?.Bob_Agent_Support_Email ??
                          'Unavailable',
                        message: `I am reaching out to inquire why there isn’t a book of business match for the following Health Sherpa policy.\n\nPolicy Holder Information:\n-Name: ${
                          cell.row.original.Client
                        }\n-Address: ${
                          cell.row.original.Residence
                        }\n-Phone Number: ${
                          cell.row.original.phone ?? 'unknown'
                        }\n-Carrier ID: ${
                          cell.row.original.carrier_id
                        }\n-Policy ID: ${
                          cell.row.original.Policies_healthSherpaId
                        }\n\nAgent Information:\n-Name: ${
                          cell.row.original.AssistorName
                        }\n-National Producer Number (NPN): ${
                          cell.row.original.AssistorNpn
                        }\n\nCould you please provide clarification on why the match is not appearing and what steps, if -any, need to be taken to resolve this?`,
                        ignored: cell.row.original.BobStatus === 'Ignore',
                        status: cell.row.original.BobStatus
                      });
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <Button
                  onClick={() =>
                    revalidateHSPolicy(
                      cell.row.original.HealthSherpaId,
                      cell.row.original.Carrier
                    )
                  }
                  icon={<RefreshIcon />}
                  size='small'
                />

                <div>
                  <span
                    style={{
                      fontStyle: 'italic',
                      fontSize: '12px'
                    }}
                  >
                    Last update:{' '}
                  </span>
                  {month && (
                    <span
                      style={{
                        fontSize: '12px'
                      }}
                    >
                      {month}-{dt}-{year}
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        }
      },
      {
        header: 'BOB Potential Matches',
        accessorKey: 'BOBPotentialMatches',
        enableResizing: false,
        enableColumnFilter: false,
        size: 200,
        Cell: ({ cell }) =>
          !cell.row.original.incomplete && (
            <Button
              text={'View'}
              size='small'
              className={style.buttonNoWrap}
              type='primaryDarkened'
              isDisabled={cell.row.original.incomplete}
              onClick={() => {
                if (!cell.row.original.HealthSherpaId) return;

                getPotentialMatches(
                  cell.row.original.HealthSherpaId,
                  cell.row.original.AssistorNpn,
                  cell.row.original.BobStatus
                );
                setPotentialMatchesData({
                  HsPolicyId: cell.row.original.HealthSherpaId,
                  BobStatus: cell.row.original.BobStatus,
                  npn: cell.row.original.AssistorNpn,
                  currentBobMatchId: '',
                  potentialBobMatches: []
                });
              }}
            />
          )
      }
    ],
    [carriersArray, onMarketplaceClick, formData]
  );

  const handleDownloadRows = useCallback(() => {
    dispatch(downloadAllCustomersData(formData));
  }, [formData]);

  const table = useMaterialReactTable({
    columns,
    data: [...data_for_table],
    enableColumnActions: false,
    enableColumnResizing: true,
    enableTopToolbar: false,
    enableSorting: false,
    enableRowSelection: false,
    enableStickyHeader: true,
    initialState: { showColumnFilters: true },
    paginationDisplayMode: 'pages',
    rowCount: Number(agent_policies_count),
    onPaginationChange: setPagination,
    onExpandedChange: active => {
      setActivePanel(active);
    },
    state: {
      pagination: pagination,
      expanded: activePanel
    },
    muiPaginationProps: {
      rowsPerPageOptions: [50, 100],
      showFirstButton: false,
      showLastButton: false,
      variant: 'outlined',
      style: {
        paddingRight: '60px'
      }
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 0
      },
      className: style.tableHeight
    },
    muiTableContainerProps: {
      id: 'customers-table-body',
      style: {
        flex: 1,
        scrollbarWidth: 'auto'
      }
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        header: ''
      }
    },
    renderBottomToolbarCustomActions: ({}) => (
      <Button
        isDisabled={isDownloadingReport}
        text='Export All'
        type='secondary'
        onClick={handleDownloadRows}
        isLoading={isDownloadingReport}
      />
    ),
    muiTableHeadCellProps: {
      style: {
        backgroundColor: '#FAFAFA',
        padding: '10px 5px',
        justifyContent: 'center'
      }
    },
    muiTableBodyCellProps: {
      style: {
        padding: '10px 5px'
      }
    },
    manualPagination: true,
    renderEmptyRowsFallback: () => (
      <h2
        style={{
          color: 'grey',
          textAlign: 'center',
          fontWeight: '400',
          fontSize: 14,
          padding: 15
        }}
      >
        No customers related to this agent
      </h2>
    ),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(0deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s'
      },
      disabled: false
    }),
    enableExpanding: true,
    enableExpandAll: false,
    muiTableBodyCellProps: ({ column }) => {
      const { columnDef } = column;
      if (columnDef.id === 'mrt-row-spacer')
        return {
          style: {
            maxWidth: '0px'
          }
        };
      return {
        className: style[`react-table-column-flex-grow-${columnDef.flexGrow}`]
      };
    },
    muiTableHeadCellProps: ({ column }) => {
      const { columnDef } = column;

      const hasFilters = columnDef.Filter;

      if (columnDef.id === 'mrt-row-spacer')
        return {
          style: {
            maxWidth: '0px'
          }
        };
      return {
        className: `${
          style[`react-table-column-flex-grow-${columnDef.flexGrow}`]
        } ${hasFilters ? style['react-table-head-content'] : ''}`,
        style: {
          display: 'flex',
          flexDirection: 'row',
          gap: '10px'
        }
      };
    },
    renderDetailPanel: ({ row }) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '25px'
          }}
        >
          {showMoreKeys.map(({ key, label, style, isCurrency }) => (
            <div
              key={key}
              style={{
                display: 'flex',
                flexDirection: 'column',
                ...style
              }}
            >
              <label
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}
              >
                {label}
              </label>
              <p>
                {isCurrency
                  ? formatMoney(Number(row?.original[key]))
                  : row?.original[key] ?? ''}
              </p>
            </div>
          ))}
          {showDateKeys.map(({ key, label }) => {
            const date = row?.original[key]?.split('T')[0]?.split('-') ?? '';
            const year = date[0],
              month = date[1],
              dt = date[2];

            return (
              <div
                key={key}
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <label
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}
                >
                  {label}
                </label>
                <p>
                  {year > 2100 ? 'N/A' : date ? `${month}-${dt}-${year}` : ''}
                </p>
              </div>
            );
          })}
        </div>
      );
    },
    muiDetailPanelProps: () => ({
      sx: () => ({
        backgroundColor: 'rgba(227,246,255,1)'
      })
    })
  });

  return (
    <div
      style={{
        width: '100%',
        flex: 1,
        display: 'flex'
      }}
    >
      <MaterialReactTable table={table} />
    </div>
  );
};

CustomersTable.propTypes = {
  data_for_table: PropTypes.array,
  agent_policies_count: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setCurrentPageSize: PropTypes.func,
  currentPage: PropTypes.number,
  setTriggerFetch: PropTypes.func,
  setPotentialMatchesData: PropTypes.func,
  getPotentialMatches: PropTypes.func,
  setActivePanel: PropTypes.func,
  activePanel: PropTypes.object,
  handleChangeSelectValue: PropTypes.func,
  applyFilters: PropTypes.func,
  zohoUserData: PropTypes.object,
  activeCarrier: PropTypes.object,
  setActiveCarrier: PropTypes.func,
  formData: PropTypes.object,
  setBobActionModalData: PropTypes.func,
  carriers: PropTypes.object,
  setLocalLoader: PropTypes.func
};

export default memo(CustomersTable);

const CircleIcon = icons['circle'];
const WarningIcon = icons['warning'];
const RefreshIcon = icons['refresh'];

const CustomCellsTop = ({ Value, Column, Small }) => {
  const colorIconConfig = {
    CarrierApptStatus: {
      NotAppointed: {
        color: '#E85C53',
        icon: <WarningIcon fontSize='small' />,
        text: 'Not Appointed'
      },
      Appointed: {
        color: '#5DBB29',
        icon: <CircleIcon fontSize='small' />,
        text: 'Appointed'
      },
      Ignore: {
        color: '#909090',
        icon: <WarningIcon fontSize='small' />,
        text: 'Ignore'
      },
      InProgress: {
        color: '#E0DA1F',
        icon: <WarningIcon fontSize='small' />,
        text: 'In Progress'
      },
      'N/A': {
        color: '#000',
        icon: <></>,
        text: 'N/A'
      }
    },
    BobStatus: {
      Matched: {
        color: '#5DBB29',
        icon: <CircleIcon fontSize='small' />,
        text: 'Matched'
      },
      ManuallyMatched: {
        color: '#008000',
        icon: <CircleIcon fontSize='small' />,
        text: 'Manually Matched'
      },
      ManuallyUnmatched: {
        color: '#C70039',
        icon: <WarningIcon fontSize='small' />,
        text: 'Manually Unmatched'
      },
      TwoWeeksPast: {
        color: '#E85C53',
        icon: <WarningIcon fontSize='small' />,
        text: '>2 Weeks Past'
      },
      PendingMatch: {
        color: '#E4D00A',
        icon: <WarningIcon fontSize='small' />,
        text: 'Pending Match'
      },
      AssignedToOther: {
        color: '#F09F0C',
        icon: <WarningIcon fontSize='small' />,
        text: 'Check AOR'
      },
      Ignore: {
        color: '#909090',
        icon: <WarningIcon fontSize='small' />,
        text: 'Ignore'
      },
      EmailedCarrier: {
        color: '#000000',
        icon: <WarningIcon fontSize='small' />,
        text: 'Emailed Carrier'
      }
    }
  };

  return (
    <>
      <div
        style={{
          color: colorIconConfig[Column][Value]?.color ?? '',
          padding: '0',
          margin: '0',
          lineHeight: '1',
          borderRadius: '13px',
          display: 'flex',
          alignItems: 'center',
          ...(Small
            ? {
                fontSize: '12px',
                fontWeight: 500,
                gap: '4px'
              }
            : {
                height: '24px',
                width: '100%',
                gap: '10px'
              })
        }}
      >
        {colorIconConfig[Column][Value]?.icon}
        {colorIconConfig[Column][Value]?.text ?? (Small ? '' : 'N/A')}
      </div>
    </>
  );
};

const CustomCells = memo(CustomCellsTop);

CustomCells.propTypes = {
  Value: PropTypes.string,
  Column: PropTypes.string,
  Small: PropTypes.bool
};
