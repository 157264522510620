import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import getCompanyVariables from 'helpers/getCompanyVariables';
import images from 'constants/images';
import style from './ownerCard.module.scss';
import { companyRoles } from 'constants/enum';
import { useSelector } from 'react-redux';

function OwnerCard({ owner = {}, loading, generalBookingUrl }) {
  const company = getCompanyVariables();
  const emailRef = useRef(null);
  const zohoData = useSelector(state => state.user.zohoData);
  const isEmailLong = (() => {
    const emailLengthThreshold = 30;
    if (owner.Email) {
      return owner.Email.length >= emailLengthThreshold;
    }
    return false;
  })();

  const isGeneralOwner = (() => {
    if (!owner) {
      return false;
    } else if (
      owner?.id === companyRoles.superAdminId ||
      owner?.role !== companyRoles.internalAgentConsultant
    ) {
      return true;
    } else if (
      zohoData?.['Position_at_Agency'] === 'Admin' ||
      zohoData?.['Custom_Comp_Level'] === 'LOA' ||
      zohoData?.['Comp_LevelsX'] === 'LOA'
    ) {
      return false;
    } else {
      return false;
    }
  })();

  const handleOpenUrl = url => {
    window.open(url, '_blank');
  };

  const imageSrc = (() => {
    if (isGeneralOwner || !owner?.imgURL) {
      return images.brand.placeHolder;
    }
    return owner.imgURL;
  })();

  return (
    <Card
      fill
      title={'Agent/Agency Consultant'}
      type={'default'}
      withHr={true}
      loading={loading}
    >
      <div className={style.img}>
        <img src={imageSrc} placeholder={images.brand.placeHolder} />
        {isGeneralOwner ? (
          <span>{companyRoles.name}</span>
        ) : (
          <span>{owner.Name}</span>
        )}
        {isGeneralOwner ? (
          <span>{companyRoles.phone}</span>
        ) : owner.Phone ? (
          <span>{owner.Phone}</span>
        ) : (
          <span>No phone available</span>
        )}
        {isGeneralOwner ? (
          <span className={`${style.email} ${isEmailLong && style.longEmail}`}>
            {companyRoles.email}
          </span>
        ) : owner.Email ? (
          <span
            ref={emailRef}
            className={`${style.email} ${isEmailLong && style.longEmail}`}
          >
            {owner.Email}
          </span>
        ) : (
          <span>No email available</span>
        )}
        {company === 'oneill' ? (
          <Button
            text='Schedule a Meeting'
            size='medium'
            type='primaryDarkened'
            className={isEmailLong && style.longEmailButton}
            onClick={() =>
              handleOpenUrl(
                `${isGeneralOwner ? generalBookingUrl : owner.zohoBooking}`
              )
            }
          />
        ) : null}
      </div>
    </Card>
  );
}

OwnerCard.propTypes = {
  owner: PropTypes.object,
  loading: PropTypes.bool,
  generalBookingUrl: PropTypes.string
};

export default OwnerCard;
