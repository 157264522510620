import axios from 'axios';
import endpoints from 'constants/endpoints';
import { safeAWSCallPost } from 'helpers/firebaseSetup';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

const INITIAL_VALUES = {
  searchName: '',
  activeFilter: [],
  agents: [],
  carriers: []
};

const useBobData = ({
  setLocalLoader,
  setFilterCount,
  setShowFilters,
  setActivePanel
}) => {
  const cancelTokenSource = axios.CancelToken.source();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [data_for_table, setDataForTable] = useState([]);
  const [bob_records_count, setBobRecordsCount] = useState(0);
  const [triggerFetch, setTriggerFetch] = useState(1);
  const [formData, setformData] = useState(INITIAL_VALUES);

  const { satementsCarriersArray } = useSelector(state => state.appointments);

  const carriersOptions = React.useMemo(() => {
    return (
      satementsCarriersArray
        .filter(carrier => carrier.Callidus_Carrier_Names !== undefined)
        // .filter(option => option.AgencyBloc_CarrierID !== null)
        .map(option => {
          return {
            type: option.type,
            name: option.name,
            id: option.AgencyBloc_CarrierID,
            Callidus_Carrier_Names: option.Callidus_Carrier_Names
          };
        })
        .concat([
          {
            type: 'Marketplace',
            name: 'Other',
            id: '',
            Callidus_Carrier_Names: 'Other'
          }
        ])
        .filter(carrier => carrier.type)
    );
  }, [satementsCarriersArray]);

  React.useEffect(() => {
    if (formData.agents.length === 0) return;
    // HS data
    getBobData(currentPage, formData, true);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, [triggerFetch]);

  const getBobData = useCallback(
    (page, filters, skipCountPages) => {
      setActivePanel({});
      setLocalLoader(true);
      safeAWSCallPost(
        endpoints.aws.getBobTabData,
        {
          page: page - 1,
          records_per_page: currentPageSize,
          filters: JSON.stringify({
            ...filters,
            agents: [],
            skipCountPages
          }),
          agents: filters.agents.map(agent => agent.id)
        },
        false,
        cancelTokenSource.token
      )
        .then(response => {
          setLocalLoader(false);
          setDataForTable(response.data.records);
          console.log(response.data.records);
          if (!skipCountPages) {
            setBobRecordsCount(response.data.totalRows);
          }
        })
        .catch(() => {
          setLocalLoader(false);
          setDataForTable([]);
          setBobRecordsCount(0);
        });

      const tbodyElement = document.getElementById('customers-bob-table-body');
      tbodyElement.scrollTop = 0;
    },
    [currentPageSize]
  );

  const clearFilters = useCallback(() => {
    setFilterCount(0);
    setformData(_formdata => {
      return {
        ...INITIAL_VALUES,
        agents: [..._formdata.agents]
      };
    });
  }, [setFilterCount, setformData]);

  const applyFilters = useCallback(() => {
    if (formData.agents.length === 0) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    getBobData(currentPage, formData, false);
    setShowFilters(false);
  }, [setCurrentPage, currentPage, getBobData, formData]);

  const handleChangeSelectValue = useCallback(
    (_values, property) => {
      let values = [];
      if (typeof _values === 'string') {
        values = formData[property][0] === _values ? [] : [_values];
      } else {
        values = _values;
      }
      if (
        formData[property].length === 0 &&
        values.length > 0 &&
        property !== 'agents'
      ) {
        setFilterCount(count => (count += 1));
      } else if (
        formData[property].length > 0 &&
        values.length === 0 &&
        property !== 'agents'
      ) {
        setFilterCount(count => (count -= 1));
      }
      setformData(prevState => ({
        ...prevState,
        [property]: values
      }));
    },
    [setformData, formData, setFilterCount]
  );

  return {
    data_for_table,
    currentPage,
    setCurrentPage,
    setCurrentPageSize,
    bob_records_count,
    formData,
    setformData,
    clearFilters,
    applyFilters,
    handleChangeSelectValue,
    setTriggerFetch,
    carriersOptions
  };
};

useBobData.propTypes = {
  loadingApptsData: PropTypes.func,
  setLocalLoader: PropTypes.func,
  setFilterCount: PropTypes.func,
  setShowFilters: PropTypes.func,
  setActivePanel: PropTypes.func
};

export default useBobData;
