import { FilterAlt } from '@mui/icons-material';
import { ClickAwayListener, Popover } from '@mui/material';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import style from '../DashboardCustomer.module.scss';

const HeaderFilterToolTip = ({
  clearFilters = () => {},
  applyFilters = () => {},
  children,
  selectedTag = null
}) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleTooltipOpen = e => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
            alignItems: 'center'
          }}
        >
          <Button
            size='small'
            onClick={handleTooltipOpen}
            icon={<FilterAlt width={5} />}
          />
          {selectedTag}
        </div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          style={{
            marginTop: '5px'
          }}
        >
          {children}
          <div
            className={style.clearApplyFilters}
            style={{
              padding: '16px 16px 16px 16px'
            }}
          >
            <Button
              text='Clear'
              size='medium'
              type='secondary'
              onClick={() => {
                clearFilters();
              }}
            />
            <Button
              text='Apply Filter'
              size='medium'
              type='primaryDarkened'
              onClick={() => {
                applyFilters();
                handleClose();
              }}
            />
          </div>
        </Popover>
      </div>
    </ClickAwayListener>
  );
};

HeaderFilterToolTip.propTypes = {
  customOnClose: PropTypes.func,
  children: PropTypes.node,
  clearFilters: PropTypes.func,
  applyFilters: PropTypes.func,
  selectedTag: PropTypes.node
};

export default HeaderFilterToolTip;
