import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import style from '../DashboardCustomer.module.scss';
import React from 'react';

const PotentialMatchesModal = ({
  potentialMatchesData,
  handleClosePotentialMatchesModal,
  loadingPotentialMatches,
  deleteBobMatch,
  //getPotentialMatches,
  confirmBobMatch
}) => {
  return (
    <Modal
      size='xlarger'
      active={potentialMatchesData.HsPolicyId}
      title='Potential Matches'
      isLoading={loadingPotentialMatches}
      footer={
        <div
          className={style.wrapButtonsSmall}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
            justifyContent: 'space-between'
          }}
        >
          <Button
            fill
            type='primaryDarkened'
            text='Close'
            onClick={handleClosePotentialMatchesModal}
          />
        </div>
      }
      closeModal={handleClosePotentialMatchesModal}
    >
      <PotentialMatchesTable
        potentialMatchesData={potentialMatchesData}
        deleteBobMatch={deleteBobMatch}
        confirmBobMatch={confirmBobMatch}
      />
    </Modal>
  );
};

PotentialMatchesModal.propTypes = {
  potentialMatchesData: PropTypes.object,
  handleClosePotentialMatchesModal: PropTypes.func,
  loadingPotentialMatches: PropTypes.bool,
  deleteBobMatch: PropTypes.func,
  getPotentialMatches: PropTypes.func,
  confirmBobMatch: PropTypes.func
};

export default PotentialMatchesModal;

const PotentialMatchesTable = ({
  potentialMatchesData,
  deleteBobMatch,
  confirmBobMatch
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50
  });

  const columns = useMemo(
    () => [
      {
        header: 'Action',
        enableResizing: false,
        size: 230,
        Cell: ({ cell }) => {
          const currentMatch =
            cell.row.original.id === potentialMatchesData.currentBobMatchId;

          return (
            <Button
              text={currentMatch ? 'Remove Association' : 'Confirm Association'}
              size='medium'
              className={style.buttonNoWrap}
              type={currentMatch ? 'danger' : 'primaryDarkened'}
              isDisabled={
                potentialMatchesData.currentBobMatchId !== '' && !currentMatch
              }
              onClick={
                currentMatch
                  ? () => {
                      deleteBobMatch();
                    }
                  : () => {
                      confirmBobMatch(
                        cell.row.original.WritingAgentNPNFromBOB,
                        cell.row.original.id
                      );
                    }
              }
            />
          );
        }
      },
      {
        header: 'Holder',
        accessorKey: 'Holder'
      },
      {
        header: 'Bob Status',
        /* eslint react/prop-types: 0 */
        Cell: ({ cell }) => {
          const dropped = cell.row.original.bobExlude === 'Y';
          const currentDate = new Date();
          const inactive =
            (cell.row.original.BOBRemovedDate &&
              new Date(cell.row.original.BOBRemovedDate) <= currentDate) ||
            (cell.row.original.BOBInactiveDate &&
              new Date(cell.row.original.BOBInactiveDate) <= currentDate);

          return (
            <div
              style={{
                color: dropped ? 'red' : inactive ? 'orange' : 'green'
              }}
            >
              {dropped ? 'Dropped' : inactive ? 'Inactive' : 'Active'}
            </div>
          );
        }
      },
      {
        header: 'Phone',
        accessorKey: 'HolderPhone'
      },
      {
        header: 'Email',
        accessorKey: 'EmailAddress',
        size: 250
      },
      {
        header: 'Carrier',
        accessorKey: 'carriername'
      },
      {
        header: 'FFM App ID',
        accessorKey: 'FFMAppId'
      },
      {
        header: 'Carrier Policy #',
        accessorKey: 'CarrierPolicyNbr'
      },
      {
        header: 'Unique Policy ID',
        accessorKey: 'UniquePolicyID'
      },
      {
        header: 'State',
        size: 90,
        accessorKey: 'HolderState'
      },
      {
        header: 'Writing Agent BOB',
        accessorKey: 'WritingAgentNameFromBOB'
      },
      {
        header: 'Removed Date',
        accessorKey: 'BOBRemovedDate',
        Cell: ({ cell }) => {
          const date = cell.getValue()?.split('T')[0]?.split('-') ?? '';
          const year = date[0],
            month = date[1],
            dt = date[2];

          return (
            <div>
              {year > 2100 ? 'N/A' : date ? `${month}-${dt}-${year}` : ''}
            </div>
          );
        }
      },
      {
        header: 'Inactive Date',
        accessorKey: 'BOBInactiveDate',
        Cell: ({ cell }) => {
          const date = cell.getValue()?.split('T')[0]?.split('-') ?? '';
          const year = date[0],
            month = date[1],
            dt = date[2];

          return (
            <div>
              {year > 2100 ? 'N/A' : date ? `${month}-${dt}-${year}` : ''}
            </div>
          );
        }
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: [...potentialMatchesData.potentialBobMatches],
    enableColumnActions: false,
    enableColumnResizing: true,
    enableTopToolbar: false,
    enableSorting: false,
    enableRowSelection: false,
    paginationDisplayMode: 'pages',
    enablePagination: false,
    rowCount: potentialMatchesData.potentialBobMatches.length,
    onPaginationChange: setPagination,
    state: {
      pagination: pagination
    },
    muiPaginationProps: {
      rowsPerPageOptions: [50, 100],
      showFirstButton: false,
      showLastButton: false,
      variant: 'outlined',
      style: {
        paddingRight: '60px'
      }
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      },
      className: style.potentialMatchesTableHeight
    },
    muiTableContainerProps: {
      id: 'customers-bob-potential-body',
      style: {
        flex: 1,
        scrollbarWidth: 'auto',
      }
    },
    muiTableHeadCellProps: {
      style: {
        backgroundColor: '#FAFAFA'
      }
    },
    manualPagination: true,
    renderEmptyRowsFallback: () => (
      <h2
        style={{
          color: 'grey',
          textAlign: 'center',
          fontWeight: '400',
          fontSize: 14,
          padding: 15
        }}
      >
        No potential BOB matches found
      </h2>
    )
  });
  return <MaterialReactTable table={table} />;
};

PotentialMatchesTable.propTypes = {
  potentialMatchesData: PropTypes.object,
  confirmBobMatch: PropTypes.func,
  deleteBobMatch: PropTypes.func
};
