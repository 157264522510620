import Button from 'components/Button/Button';
import icons from 'constants/icons';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadAllBOBData } from 'state/actions/healthSherpa';
import style from '../DashboardCustomerBob.module.scss';

const showMoreKeys = [
  {
    key: 'callidus_books_of_business_FFMAppId',
    label: 'FFM App Id'
  },
  {
    key: 'callidus_books_of_business_CarrierPolicyNbr',
    label: 'Policy Number'
  },
  {
    key: 'callidus_books_of_business_EmailAddress',
    label: 'Holder Email'
  },
  {
    key: 'callidus_books_of_business_HolderPhone',
    label: 'Holder Phone'
  }
];

const showDateKeys = [
  {
    key: 'callidus_books_of_business_EffectiveDatefromBOB',
    label: 'Submission Date'
  },
  {
    key: 'BOBInactiveDate',
    label: 'Inactive Date'
  },
  {
    key: 'callidus_books_of_business_BOBLastUpdateDate',
    label: 'Last Modified Date'
  }
];

const BobTable = ({
  setCurrentPage,
  currentPage,
  data_for_table,
  bob_records_count,
  setCurrentPageSize,
  setTriggerFetch,
  activePanel,
  setActivePanel,
  formData,
  carriersOptions
}) => {
  const dispatch = useDispatch();
  const { isDownloadingReport } = useSelector(state => state.commissions);

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 50
  });

  useEffect(() => {
    if (currentPage - 1 !== pagination.pageIndex) {
      setPagination(current => {
        return {
          ...current,
          pageIndex: currentPage - 1
        };
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage - 1 !== pagination.pageIndex) {
      setTriggerFetch(current => current + 1);
      setCurrentPage(pagination.pageIndex + 1);
    }
    setActivePanel({});
  }, [pagination.pageIndex]);

  useEffect(() => {
    setTriggerFetch(current => current + 1);
    setCurrentPageSize(pagination.pageSize);
    setActivePanel({});
  }, [pagination.pageSize]);

  const columns = useMemo(
    () => [
      /* eslint react/prop-types: 0 */
      {
        header: 'Holder',
        accessorKey: 'callidus_books_of_business_Holder',
        flexGrow: 1,
        size: 130
      },
      {
        header: 'DOB',
        accessorKey: 'callidus_books_of_business_holderdob',
        flexGrow: 1,
        size: 130,
        Cell: ({ cell }) => {
          const date = (cell.getValue() ?? '//').split('/') ?? '';
          const year = date[2],
            month = date[0],
            dt = date[1];

          return (
            <div>
              {year > 2100 ? 'N/A' : date ? `${month}-${dt}-${year}` : ''}
            </div>
          );
        }
      },
      {
        header: 'State',
        accessorKey: 'callidus_books_of_business_HolderState',
        size: 80
      },
      {
        header: 'Carrier',
        accessorKey: 'callidus_books_of_business_carriername',
        flexGrow: 1,
        size: 120
      },
      {
        header: 'NPN',
        accessorKey: 'callidus_books_of_business_WritingAgentNPNFromBOB',
        size: 100
      },
      {
        header: 'Agent',
        accessorKey: 'callidus_books_of_business_WritingAgentNameFromBOB',
        flexGrow: 1,
        size: 120
      }
    ],
    []
  );

  const handleDownloadRows = useCallback(() => {
    dispatch(
      downloadAllBOBData({
        ...formData,
        carriers: carriersOptions.map(x => x.Callidus_Carrier_Names).flat()
      })
    );
  }, [formData]);

  const table = useMaterialReactTable({
    columns,
    data: [...data_for_table],
    enableColumnActions: false,
    enableColumnResizing: true,
    enableTopToolbar: false,
    enableSorting: false,
    enableRowSelection: false,
    paginationDisplayMode: 'pages',
    rowCount: Number(bob_records_count),
    onPaginationChange: setPagination,
    onExpandedChange: active => {
      setActivePanel(active);
    },
    state: {
      pagination: pagination,
      expanded: activePanel
    },
    muiPaginationProps: {
      rowsPerPageOptions: [50, 100],
      showFirstButton: false,
      showLastButton: false,
      variant: 'outlined',
      style: {
        paddingRight: '60px'
      }
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 0
      },
      className: style.tableHeight
    },
    muiTableContainerProps: {
      id: 'customers-bob-table-body',
      style: {
        flex: 1
      }
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        header: ''
      }
    },
    renderBottomToolbarCustomActions: ({}) => (
      <Button
        isDisabled={isDownloadingReport}
        text='Export All'
        type='secondary'
        onClick={handleDownloadRows}
        isLoading={isDownloadingReport}
      />
    ),
    muiTableHeadCellProps: {
      style: {
        backgroundColor: '#FAFAFA',
        padding: '10px 5px',
        justifyContent: 'center'
      }
    },
    muiTableBodyCellProps: {
      style: {
        padding: '10px 5px'
      }
    },
    manualPagination: true,
    renderEmptyRowsFallback: () => (
      <h2
        style={{
          color: 'grey',
          textAlign: 'center',
          fontWeight: '400',
          fontSize: 14,
          padding: 15
        }}
      >
        No bob records
      </h2>
    ),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(0deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s'
      },
      disabled: false
    }),
    enableExpanding: true,
    enableExpandAll: false,
    muiTableBodyCellProps: ({ column }) => {
      const { columnDef } = column;
      if (columnDef.id === 'mrt-row-spacer')
        return {
          style: {
            maxWidth: '0px'
          }
        };
      return {
        className: style[`react-table-column-flex-grow-${columnDef.flexGrow}`]
      };
    },
    muiTableHeadCellProps: ({ column }) => {
      const { columnDef } = column;

      const hasFilters = columnDef.Filter;

      if (columnDef.id === 'mrt-row-spacer')
        return {
          style: {
            maxWidth: '0px'
          }
        };
      return {
        className: `${
          style[`react-table-column-flex-grow-${columnDef.flexGrow}`]
        } ${hasFilters ? style['react-table-head-content'] : ''}`,
        style: {
          display: 'flex',
          flexDirection: 'row',
          gap: '10px'
        }
      };
    },
    renderDetailPanel: ({ row }) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '25px'
          }}
        >
          {showMoreKeys.map(({ key, label, style, isCurrency }) => (
            <div
              key={key}
              style={{
                display: 'flex',
                flexDirection: 'column',
                ...style
              }}
            >
              <label
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}
              >
                {label}
              </label>
              <p>
                {isCurrency
                  ? formatMoney(Number(row?.original[key]))
                  : row?.original[key] ?? ''}
              </p>
            </div>
          ))}
          {showDateKeys.map(({ key, label }) => {
            const date = row?.original[key]?.split('/') ?? '';
            const year = date[2],
              month = date[0],
              dt = date[1];

            return (
              <div
                key={key}
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <label
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}
                >
                  {label}
                </label>
                <p>
                  {year > 2100 ? 'N/A' : date ? `${month}-${dt}-${year}` : ''}
                </p>
              </div>
            );
          })}
        </div>
      );
    },
    muiDetailPanelProps: () => ({
      sx: () => ({
        backgroundColor: 'rgba(227,246,255,1)'
      })
    })
  });

  return (
    <div
      style={{
        width: '100%',
        flex: 1,
        display: 'flex'
      }}
    >
      <MaterialReactTable table={table} />
    </div>
  );
};

BobTable.propTypes = {
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  data_for_table: PropTypes.array,
  bob_records_count: PropTypes.number,
  setCurrentPageSize: PropTypes.func,
  setTriggerFetch: PropTypes.func,
  setActivePanel: PropTypes.func,
  activePanel: PropTypes.object,
  formData: PropTypes.object,
  carriersOptions: PropTypes.array
};

export default memo(BobTable);

const CircleIcon = icons['circle'];
const WarningIcon = icons['warning'];

const CustomCells = ({ Value, Column }) => {
  const colorIconConfig = {
    StatusfromBOB: {
      NotAppointed: {
        color: '#E85C53',
        icon: <WarningIcon fontSize='small' />,
        text: 'Not Appointed'
      },
      Active: {
        color: '#5DBB29',
        icon: <></>,
        text: 'Active'
      },
      Ignore: {
        color: '#909090',
        icon: <WarningIcon fontSize='small' />,
        text: 'Ignore'
      },
      InProgress: {
        color: '#E0DA1F',
        icon: <WarningIcon fontSize='small' />,
        text: 'In Progress'
      }
    },
    BobStatus: {
      Matched: {
        color: '#5DBB29',
        icon: <CircleIcon fontSize='small' />,
        text: 'Matched'
      },
      ManuallyMatched: {
        color: '#008000',
        icon: <CircleIcon fontSize='small' />,
        text: 'Manually Matched'
      },
      ManuallyUnmatched: {
        color: '#C70039',
        icon: <WarningIcon fontSize='small' />,
        text: 'Manually Unmatched'
      },
      TwoWeeksPast: {
        color: '#E85C53',
        icon: <WarningIcon fontSize='small' />,
        text: '>2 Weeks Past'
      },
      PendingMatch: {
        color: '#E4D00A',
        icon: <WarningIcon fontSize='small' />,
        text: 'Pending Match'
      },
      AssignedToOther: {
        color: '#F09F0C',
        icon: <WarningIcon fontSize='small' />,
        text: 'Check AOR'
      },
      Ignore: {
        color: '#909090',
        icon: <WarningIcon fontSize='small' />,
        text: 'Ignore'
      },
      EmailedCarrier: {
        color: '#000000',
        icon: <WarningIcon fontSize='small' />,
        text: 'Emailed Carrier'
      }
    }
  };

  return (
    <>
      <div
        style={{
          color: colorIconConfig[Column][Value]?.color ?? '',
          height: '24px',
          width: '100%',
          padding: '0',
          margin: '0',
          lineHeight: '1',
          borderRadius: '13px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px'
        }}
      >
        {colorIconConfig[Column][Value]?.icon}
        {colorIconConfig[Column][Value]?.text ?? 'N/A'}
      </div>
    </>
  );
};

CustomCells.propTypes = {
  Value: PropTypes.string,
  Column: PropTypes.string
};
