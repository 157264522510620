import React, { useEffect, useState } from 'react';
import withLocalLoader from 'components/HOCs/withLocalLoader';
import PropTypes from 'prop-types';
import Card from 'components/Card/Card';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField
} from '@mui/material';
import Button from 'components/Button/Button';
import { Height, PlayArrow } from '@mui/icons-material';
import AutocompleteInput from 'components/Autocomplete/AutocompleteInput';
import {
  getAppointmentsViewData,
  downloadCommissionsData,
  getSpecificAgencyData,
  getSelectedAgenciesAgents,
  getStatementsPeriods
} from 'state/actions/actions';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
// import { generatestatementPeriods } from 'helpers/commissions/commissions';
import { USER_TYPES } from 'constants/enum';
import { CircularProgress } from '@mui/material';
// import { getComissionPeriods } from 'state/actions/commissions';

// const timeLog = cosa => console.log(`${new Date()}: ${cosa}`);

const INITIAL_VALUES = {
  periods: [],
  carriers: [],
  agencies: [],
  agents: [],
  orderBy: 'Carrier',
  includeAllCarriers: true
};

const isLOA = obj => {
  const compLevel =
    obj['Custom_Comp_Level'] !== null
      ? obj['Custom_Comp_Level']
      : obj['Comp_LevelsX'];
  return compLevel === 'LOA';
};

const StatementsView = ({ setLocalLoader, localLoader }) => {
  //Hoooks
  const dispatch = useDispatch();
  const cancelTokenSource = axios.CancelToken.source();
  // States from redux
  const { satementsCarriersArray } = useSelector(state => state.appointments);
  const { agencyInformation } = useSelector(
    state => state.agencyManagement.agencyData
  );
  const { statementPeriods } = useSelector(state => state.appointments);
  const { zohoData } = useSelector(state => state.user);
  const { isDownloadingReport } = useSelector(state => state.commissions);
  const currentAgenciesAgentsDict = useSelector(
    state => state.agencyManagement.comissions_tab_agents
  );
  // Local states
  const [formData, setformData] = useState(INITIAL_VALUES);
  // Mulstiselect options

  // const [carriersOptions, setCarriersOptions] = useState();
  // const [agenciesOptions, setAgenciesOptions] = useState();
  // const [mainAgentsOptions, setMainAgentsOptions] = useState();
  // const [agentsOptions, setAgentsOptions] = useState();
  const notAdminAndLOA = React.useMemo(
    () => zohoData.Position_at_Agency !== USER_TYPES.ADMIN && isLOA(zohoData),
    [zohoData]
  );
  const groupedPeriodOptions = React.useMemo(() => {
    return statementPeriods.map(periodObj => {
      const [periodMonth, periodDay, periodYear] = periodObj.name.split('/');
      const monthsNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];
      return {
        ...periodObj,
        month: `${monthsNames[Number(periodMonth) - 1]} ${periodYear}`
      };
    });
  }, [statementPeriods]);

  const carriersOptions = React.useMemo(() => {
    // carriers = carriers.filter(
    //   carrier => carrier.Callidus_Carrier_Names !== null
    // );
    return (
      satementsCarriersArray
        .filter(carrier => carrier.Callidus_Carrier_Names !== undefined)
        // .filter(option => option.AgencyBloc_CarrierID !== null)
        .map(option => {
          return {
            type: option.type,
            name: option.name,
            id: option.AgencyBloc_CarrierID,
            Callidus_Carrier_Names: option.Callidus_Carrier_Names
          };
        })
        .concat([
          {
            type: 'Marketplace',
            name: 'Other',
            id: '',
            Callidus_Carrier_Names: 'Other'
          }
        ])
        .filter(carrier => carrier.type)
    );
  }, [satementsCarriersArray]);
  // console.log(carriersOptions);
  const [allAgenciesAreLoa, setAllAgenciesAreLoa] = useState(false);
  const agenciesOptions = React.useMemo(() => {
    // If user is not admin, don't compute any of this
    if (zohoData.Position_at_Agency !== USER_TYPES.ADMIN) return [];

    // Preselects agency
    if (agencyInformation?.id?._value && agencyInformation?.name?._value) {
      setformData(prevState => ({
        ...prevState,
        ['agencies']: [
          {
            id: agencyInformation?.id?._value,
            name: agencyInformation?.name?._value
          }
        ]
      }));
    }
    const rawAgenciesOptions =
      agencyInformation.Downline_Agencies_Three?.concat([agencyInformation]);
    const filteredAgenciesOptions = rawAgenciesOptions?.filter(
      f => f.Comm_Level_Tier_Auto !== 'LOA'
    );
    if (
      rawAgenciesOptions?.length !== 0 &&
      filteredAgenciesOptions?.length === 0
    ) {
      setAllAgenciesAreLoa(true);
    }
    const returnAgenciesOptions =
      filteredAgenciesOptions?.map(agency => ({
        id: agency.id._value,
        name: agency.name._value
      })) || [];

    // Gets all the agents from all the agencies from the tree
    if (zohoData.Position_at_Agency === USER_TYPES.ADMIN) {
      const ids = [...returnAgenciesOptions?.map(agency => agency.id)];
      dispatch(
        getSelectedAgenciesAgents(
          () => {},
          currentAgenciesAgentsDict,
          ids,
          cancelTokenSource
        )
      );
    }

    return returnAgenciesOptions;
  }, [agencyInformation.Downline_Agencies_Three]);
  const mainAgentsOptions = React.useMemo(() => {
    const mainOptions =
      zohoData.NPN !== null &&
      zohoData.NPN !== 0 &&
      zohoData.NPN !== '' &&
      zohoData.NPN !== '0' &&
      zohoData.NPN !== 1 &&
      zohoData.NPN !== '1' &&
      zohoData.NPN.toString().length > 4 &&
      zohoData.NPN.toString().length <= 9
        ? [
            {
              type: 'Main Agent',
              name: zohoData.First_Name + ' ' + zohoData.Last_Name,
              npn: zohoData.NPN,
              Agency_Name: 'Main Agent',
              Agency_Id: zohoData.Account_Name?.id,
              id: zohoData?.id
            }
          ]
        : [];
    return mainOptions;
  }, [zohoData.NPN, zohoData.First_Name, zohoData.Last_Name, zohoData.id]);
  const [legitHasNoAgents, setLegitHasNoAgents] = useState(false);
  const agentsOptions = React.useMemo(() => {
    const selectedAgenciesAgents = Object.keys(currentAgenciesAgentsDict)
      .map(selected_agency_id => currentAgenciesAgentsDict[selected_agency_id])
      .filter(x => x)
      .flat()
      .filter(option => !isLOA(option))
      .map(option => {
        return {
          type: 'Downline Agent',
          name: option.First_Name + ' ' + option.Last_Name,
          npn: option.NPN,
          Agency_Id: option.Agency_Id,
          Agency_Name: option.Agency,
          id: option.id
        };
      })
      .filter(
        option =>
          option.npn !== null &&
          option.npn !== 0 &&
          option.npn !== '' &&
          option.npn !== '0' &&
          option.npn !== 1 &&
          option.npn !== '1' &&
          option.npn.toString().length > 4 &&
          option.npn.toString().length <= 9
      )
      .filter(option => option.npn !== zohoData.NPN);
    if (
      Object.keys(currentAgenciesAgentsDict).length === 0 &&
      selectedAgenciesAgents.length === 0
    )
      setLegitHasNoAgents(true);
    // If user is LOA, return only other agents
    if (isLOA(zohoData)) return selectedAgenciesAgents;
    // If user is not LOA, returns itself as well.
    return [...mainAgentsOptions, ...selectedAgenciesAgents];
  }, [currentAgenciesAgentsDict]);

  // Effects
  // Gets data from backend
  useEffect(() => {
    const getData = async () => {
      setLocalLoader(true);
      dispatch(
        getStatementsPeriods(
          () => {},
          () => {}
        )
      );
      dispatch(
        getAppointmentsViewData(
          () => {},
          () => {},
          true
        )
      );
      if (zohoData.Position_at_Agency !== USER_TYPES.ADMIN) return;
      dispatch(
        getSpecificAgencyData(
          () => {},
          zohoData?.Account_Name?.id,
          () => {}
        )
      );

      // return () => {
      //   cancelTokenSource.cancel('API Call Canceled');
      // };
    };
    getData();
  }, []);

  // Preselects main agent
  useEffect(() => {
    // Preselects the logged in agent if not LOA
    if (isLOA(zohoData)) return;
    setformData(prevData => ({
      ...prevData,
      agents: mainAgentsOptions
    }));
  }, [mainAgentsOptions]);

  // If all data from all sources has finished loading, turns off the local loader
  useEffect(() => {
    if (notAdminAndLOA) return setLocalLoader(false);
    const statementPeriodsAreLoaded = statementPeriods.length > 0;
    const carriersOptionsAreLoaded = carriersOptions.length > 1;
    const agenciesOptionsAreLoaded =
      agenciesOptions.length > 0 ||
      allAgenciesAreLoa ||
      zohoData.Position_at_Agency !== USER_TYPES.ADMIN;
    const agentsOptionsAreLoaded = agentsOptions.length > 0 || legitHasNoAgents;
    console.log('-----------------');
    console.log(`statementPeriodsAreLoaded: ${statementPeriodsAreLoaded}`);
    console.log(`carriersOptionsAreLoaded: ${carriersOptionsAreLoaded}`);
    console.log(`agenciesOptionsAreLoaded: ${agenciesOptionsAreLoaded}`);
    console.log(`agentsOptionsAreLoaded: ${agentsOptionsAreLoaded}`);
    if (
      statementPeriodsAreLoaded &&
      carriersOptionsAreLoaded &&
      agenciesOptionsAreLoaded &&
      agentsOptionsAreLoaded
    ) {
      setLocalLoader(false);
    }
  }, [statementPeriods, carriersOptions, agenciesOptions, agentsOptions]);
  const handleChangeSelectValue = (values, property) => {
    setformData(prevState => ({
      ...prevState,
      [property]: values
    }));
  };

  const handleRunReport = () => {
    dispatch(downloadCommissionsData(formData, carriersOptions));
  };

  const handleChangeIncludeAllCarriers = () => {
    setformData(prevState => ({
      ...prevState,
      includeAllCarriers: !prevState.includeAllCarriers,
      carriers: []
    }));
  };

  const handleResetValues = () => {
    // const initialValues = {
    //   ...INITIAL_VALUES,
    //   agents: [
    //     {
    //       type: 'Main Agent',
    //       name: zohoData.First_Name + ' ' + zohoData.Last_Name,
    //       npn: zohoData.NPN
    //     }
    //   ]
    // };
    setformData(INITIAL_VALUES);
  };
  // Validations
  const agentOrAgencySelected = React.useMemo(
    () => formData.agents.length === 0 && formData.agencies.length === 0,
    [formData.agents, formData.agencies]
  );
  const validateReportData = React.useMemo(
    () =>
      !formData.orderBy ||
      agentOrAgencySelected ||
      (formData.carriers.length === 0 && !formData.includeAllCarriers) ||
      formData.periods.length === 0 ||
      isDownloadingReport,
    [
      formData.orderBy,
      formData.carriers,
      formData.includeAllCarriers,
      formData.periods,
      agentOrAgencySelected,
      isDownloadingReport
    ]
  );

  return (
    <Card fill container title='Run Commission Statements' titleType='primary'>
      {notAdminAndLOA ? (
        <h4>
          Your commission level is LOA, please contact the admin at your upline
          agency for your commission statements.
        </h4>
      ) : (
        <>
          <h4>
            The statement for the selected period will be downloaded as a CSV
            after hitting {'Run report'}.
          </h4>
          <FormGroup>
            <Stack mb={2}>
              <AutocompleteInput
                showScrollbar
                multiple
                options={groupedPeriodOptions}
                groupBy={option => option.month}
                selectAllByGroup={true}
                id='monthandyear'
                value={formData.periods}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={option => option.name}
                onValueChanges={e => handleChangeSelectValue(e, 'periods')}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Period'}
                    placeholder={'Select periods'}
                    error={formData.periods.length === 0}
                    helperText={
                      formData.periods.length === 0
                        ? 'You need to select at least 1 period'
                        : ''
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Stack>

            <Stack mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeIncludeAllCarriers}
                    checked={formData.includeAllCarriers}
                  />
                }
                label={'Include All Carriers'}
              />
            </Stack>

            {!formData.includeAllCarriers ? (
              <Stack mb={3}>
                <AutocompleteInput
                  showScrollbar
                  multiple
                  loading={localLoader}
                  options={carriersOptions
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .sort((a, b) => -b.type.localeCompare(a.type))}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  groupBy={option => option.type}
                  getOptionLabel={option => option.name}
                  id='carriers'
                  value={formData.carriers}
                  onValueChanges={e => handleChangeSelectValue(e, 'carriers')}
                  disabled={formData.includeAllCarriers}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={
                        formData.includeAllCarriers
                          ? 'All Carrier(s)'
                          : 'Carrier(s)'
                      }
                      placeholder={'Select carriers'}
                      error={
                        formData.carriers.length === 0 &&
                        !formData.includeAllCarriers
                      }
                      helperText={
                        formData.carriers.length === 0 &&
                        !formData.includeAllCarriers
                          ? 'You need to select at least 1 carrier'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: !formData.includeAllCarriers
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {localLoader ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Stack>
            ) : null}
            {zohoData.Position_at_Agency === USER_TYPES.ADMIN && (
              <Stack mb={3}>
                <AutocompleteInput
                  showScrollbar
                  multiple
                  loading={localLoader}
                  loadingBlocksOptions={true}
                  options={agenciesOptions.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={option => option.name}
                  id='agencies'
                  value={formData.agencies}
                  onValueChanges={e => {
                    handleChangeSelectValue(e, 'agencies');
                  }}
                  // disabled={formData.includeAllCarriers}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={'Payee Agency(s)'}
                      placeholder={'Select agencies'}
                      error={agentOrAgencySelected}
                      helperText={
                        agentOrAgencySelected
                          ? 'You need to select at least 1 agency or 1 agent'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      // InputLabelProps={{
                      //   shrink: !formData.includeAllCarriers
                      // }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {localLoader ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Stack>
            )}

            {zohoData.Position_at_Agency === USER_TYPES.ADMIN && (
              <Stack mb={3}>
                <AutocompleteInput
                  showScrollbar
                  label={'agents'}
                  multiple
                  selectAll
                  loading={localLoader}
                  options={localLoader ? [] : agentsOptions}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  groupBy={option => option.Agency_Name}
                  getOptionLabel={option => option.name}
                  id='downlineagents'
                  value={localLoader ? [] : formData.agents}
                  onValueChanges={e => handleChangeSelectValue(e, 'agents')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={'Payee Agent(s)'}
                      placeholder={'Select agents'}
                      error={agentOrAgencySelected}
                      helperText={
                        agentOrAgencySelected
                          ? 'You need to select at least 1 agency or 1 agent'
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {localLoader ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                  selectAllByGroup={true}
                />
              </Stack>
            )}
            {/* <Stack mb={3}>
              <AutocompleteInput
                label={'Order by'}
                placeholder={'Type email'}
                options={['Carrier', 'Policyholder']}
                disableSearch
                id='grouped-by'
                value={formData.orderBy}
                onValueChanges={e => handleChangeValue(e, 'orderBy')}
                error={!formData.orderBy}
              />
            </Stack> */}

            <Stack direction={'row'} spacing={2}>
              <Button
                text={'Run report'}
                type={'primaryDarkened'}
                rounded={'large'}
                icon={<PlayArrow />}
                onClick={handleRunReport}
                disabled={validateReportData}
                isLoading={isDownloadingReport}
              />
              <Button
                text={'Reset form'}
                type={'default'}
                outlined
                rounded={'large'}
                // icon={<Clear />}
                onClick={handleResetValues}
              />
              {/* <Button
                text={'Test'}
                type={'primaryDarkened'}
                rounded={'large'}
                icon={<PlayArrow />}
                onClick={() => {
                  console.log('done');
                  axios({
                    method: 'GET',
                    url: 'http://oneillalb-1322211799.us-east-1.elb.amazonaws.com/callidusstatements/bulk?carriers=Aetna,Ambetter,Anthem,AvMed,BCBS,BCBS AZ,BCBS IL,BCBS MI,BCBS MT,BCBS NC,BCBS OK,BCBS SC,BCBS TN,BCBS TX,CareSource,Christus Health,Cigna,Friday,Health First,Medica,Molina,Oscar,Providence Health Plan,United Health Care&agents=&agencies=4738157000010316014&periods=07/07/2023,07/24/2023,08/03/2023',
                    // data: parameters,
                    headers: {
                      api_key: 'ebz40wYq8n'
                    }
                  })
                    .then(res => {
                      console.log('res');
                      console.log(res);
                    })
                    .catch(err => {
                      console.log('err');
                      console.log(err);
                    });
                }}
                // disabled={validateReportData}
                // isLoading={isDownloadingReport}
              /> */}
            </Stack>
          </FormGroup>
        </>
      )}
    </Card>
  );
};

StatementsView.propTypes = {
  localLoader: PropTypes.bool,
  setLocalLoader: PropTypes.func
};

export default withLocalLoader(
  StatementsView,
  true,
  true,
  'Commissions',
  'Statements'
);
