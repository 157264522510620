import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import DashboardCustomers from './DashboardCustomers';
import {
  getAppointmentsViewData,
  addRequestedAppointments
} from 'state/actions/actions';

function mapStateToProps({ appointments, user }) {
  return {
    carriers: appointments.carriers,
    appointedCarriers: appointments.appointedCarriers,
    zohoUserData: user.zohoData
  };
}

export default connect(mapStateToProps, {
  getAppointmentsViewData,
  addRequestedAppointments
})(withLocalLoader(DashboardCustomers, false, true, 'Customers'));
