import React, { useEffect, useState, useRef } from 'react';
import style from './verification.module.scss';
import images from 'constants/images';
import CodeVerifier from './CodeVerifier';
import Button from 'components/Button/Button';
import useCodeValidator from '../useCodeValidator';
import { useDispatch } from 'react-redux';
import { getUser } from 'services/user';
import PropTypes from 'prop-types';
import { siteLoaded, siteLoading } from 'state/actions/site';
import useCodeSender from '../useCodeSender';

function VerificationCode({
  resolver = null,
  userCredentials: currentCredentials = null,
  phoneNumber = '',
  verificationId = '',
  //setVerificationId,
  setUserCredentials = null,
  reloadPortal = false,
  newPhone = false,
  newPhoneUpdate = false
}) {
  const dispatch = useDispatch();

  const { code, setCode, error, validate, isLoading, userCredentials } =
    useCodeValidator(
      verificationId,
      resolver,
      currentCredentials,
      newPhoneUpdate
    );

  const [seconds, setSeconds] = useState(30);
  const [done, setDone] = useState(false);
  const interval = useRef();

  const { sendCode, isLoading: sendingCode } = useCodeSender(
    'recaptcha-container',
    resolver
  );

  useEffect(() => {
    if (done) return;

    function tick() {
      setSeconds(prevSeconds => prevSeconds - 1);
    }
    interval.current = setInterval(() => tick(), 1000);
  }, [done]);

  useEffect(() => {
    if (seconds <= 0) {
      clearInterval(interval.current);
      setDone(true);
    }
  }, [seconds]);

  async function logIn() {
    if (userCredentials) {
      if (reloadPortal) {
        dispatch(siteLoading());
        const userData = await getUser(userCredentials);
        dispatch({ type: 'SET_USER_DATA', payload: userData });
        dispatch({ type: 'LOG_IN' });
        dispatch(siteLoaded());
      }
      setUserCredentials(userCredentials);
    }
  }

  useEffect(() => {
    logIn();
  }, [userCredentials]);

  return (
    <>
      <div className={newPhone && newPhoneUpdate ? {} : style.login}>
        <div className={newPhone && newPhoneUpdate ? {} : style.formContainer}>
          {!newPhone && (
            <>
              <img src={images.brand.fullLogo} alt={"O'Neill Logo"} />
              <p className={style.subTitle}>{"O'Neill Host Portal Login"} </p>
            </>
          )}
          <p className={style.description}>
            We sent you a 6 digit code to <br />{' '}
            {phoneNumber ?? 'Your Phone number'} . Please enter the code to log
            in.
          </p>
          <div
            style={{
              display: 'flex',
              marginBlock: '15px',
              justifyContent: 'center'
            }}
          >
            <Button
              type='primary'
              isLoading={sendingCode}
              disabled={!done}
              text={done ? 'Resend Code' : `Wait: ${seconds}`}
              onClick={async e => {
                e.preventDefault();
                try {
                  await sendCode();
                  setSeconds(15);
                  setDone(false);
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          </div>
          <div>Enter 6 digit code</div>
          <div className={style.submitButton}>
            <CodeVerifier
              length={6}
              getCode={code => setCode(code)}
              submitCode={() => {}}
              isLoading={isLoading}
            />
            {error ? <p>{error}</p> : null}
            <Button
              id={'recaptcha-container'}
              isLoading={false}
              disabled={code.length < 6}
              className={style.submitButton}
              text={newPhone ? 'Continue' : 'Submit and login'}
              onClick={e => {
                e.preventDefault();
                validate();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

VerificationCode.propTypes = {
  resolver: PropTypes.object,
  userCredentials: PropTypes.object,
  phoneNumber: PropTypes.string,
  verificationId: PropTypes.string,
  setVerificationId: PropTypes.func,
  setUserCredentials: PropTypes.func,
  reloadPortal: PropTypes.bool,
  newPhone: PropTypes.bool,
  newPhoneUpdate: PropTypes.bool
};

export default VerificationCode;
