import { useState } from 'react';
import firebase from 'firebase/compat/app';
// import { getUser } from 'services/user';
// import { useDispatch } from 'react-redux';
// import { LOG_IN, SET_USER_DATA } from 'constants/types';
import loginFormValidator from './loginFormValidator';

function useLogin() {
  const [isLogIn, setIsLogIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    message: '',
    email: '',
    password: '',
    mfa: false
  });
  const [resolver, setResolver] = useState(null);
  const [userCredentials, setUserCredentials] = useState(null);

  const handleEmailChange = e => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: '' });
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
    setErrors({ ...errors, password: '' });
  };

  async function login() {
    setIsLogIn(true);
    try {
      const { errors, isValid } = loginFormValidator({ email, password });
      if (!isValid) setErrors({ ...errors });
      const userCredentials = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      setUserCredentials(userCredentials);
      // const userData = await getUser(userCredentials);
      // console.log(userData);
      // dispatch({ type: SET_USER_DATA, payload: userData });
      // dispatch({ type: LOG_IN });
      // dispatch(setNeedMfa(true));
    } catch (e) {
      // console.log(e);
      if (e.code === 'auth/wrong-password')
        setErrors({ ...errors, password: 'Wrong password' });
      else if (e.code === 'auth/user-not-found')
        setErrors({ ...errors, email: 'No user found with this e-mail' });
      else if (e.code === 'auth/multi-factor-auth-required') {
        const resolver = e.resolver;
        setResolver(resolver);
      } else setErrors({ ...errors, message: "There's been an error" });
    }
    setIsLogIn(false);
  }

  return {
    login,
    isLogIn,
    email,
    setEmail: handleEmailChange,
    password,
    setPassword: handlePasswordChange,
    errors,
    resolver,
    userCredentials
  };
}

export default useLogin;
