import React, { useEffect } from 'react';
import style from './reauthenticateForm.module.scss';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';
import useReauthenticate from './useReauthenticate';

function ReauthenticateForm({
  setResolver,
  setUserCredentials,
}) {
  const {
    error,
    userCredentials,
    loading,
    resolver,
    setPassword,
    reauthenticate,
  } = useReauthenticate();

  useEffect(() => {
    if (resolver) setResolver(resolver);
    if (userCredentials) setUserCredentials(userCredentials);
  }, [resolver, userCredentials]);

  return (
    <form className={style.genericForm}>
      <Input
        name='password'
        label='Password'
        type='password'
        error={error}
        placeholder='Enter your password'
        onChange={e => setPassword(e.target.value)}
        margin='dense'
      />
      <Button
        isLoading={loading}
        disabled={loading}
        text={'login'}
        type={'primary'}
        size={'medium'}
        rounded={'large'}
        fill
        className={style.formButton}
        onClick={e => {
          e.preventDefault();
          reauthenticate();
        }}
        margin='dense'
      />
    </form>
  );
}

ReauthenticateForm.propTypes = {
  setResolver: PropTypes.func,
  setUserCredentials: PropTypes.func,
};

export default ReauthenticateForm;
