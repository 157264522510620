import React, { useEffect } from 'react';
//HELPERS
import style from './loginForm.module.scss';
//COMPONENTS
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
//EXTERNALS
// import { useHistory } from 'react-router-dom';
import useLogin from './useLogin';
import PropTypes from 'prop-types';

function LoginForm({
  setResolver,
  setUserCredentials,
}) {
  console.log('on login form');
  const {
    login,
    isLogIn,
    setEmail,
    setPassword,
    errors,
    resolver,
    userCredentials,
  } = useLogin();

  useEffect(() => {
    console.log('in loginformusefect');
    console.log(resolver);
    console.log(userCredentials);
    if (resolver || userCredentials) {
      setResolver(resolver);
      setUserCredentials(userCredentials);
    }
  }, [resolver, userCredentials]);

  return (
    <form className={style.genericForm}>
      <Input
        name='email'
        label='E-mail'
        type='mail'
        error={errors.email}
        placeholder='Enter your e-mail'
        onChange={setEmail}
        autoFocus
        margin='dense'
      />
      <Input
        name='password'
        label='Password'
        type='password'
        error={errors.password}
        placeholder='Enter your password'
        onChange={setPassword}
        margin='dense'
      />
      <Button
        isLoading={isLogIn}
        disabled={isLogIn}
        text={'login'}
        type={'primary'}
        size={'medium'}
        rounded={'large'}
        fill
        className={style.formButton}
        onClick={e => {
          e.preventDefault();
          login();
        }}
        margin='dense'
      />
    </form>
  );
}

LoginForm.propTypes = {
  setResolver: PropTypes.func,
  setUserCredentials: PropTypes.func,
};

export default LoginForm;
