import React, { useEffect } from 'react';
import {
  Checkbox,
  TextField,
  Autocomplete,
  createFilterOptions,
  FormHelperText
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ChipStyles from './ChipStyles';
import PropTypes from 'prop-types';
import styles from './AutocompleteInput.module.scss';
import { CircularProgress } from '@mui/material';
import Button from 'components/Button/Button';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const filterByAllKeys = (arr1, arr2) => {
  // We want all elements from arr1 that don't exist in arr2
  // Comapring key by key
  const allKeys = Object.keys(arr1[0]);
  const result = [];
  for (let i = 0; i < arr1.length; i++) {
    const arr1Element = arr1[i];
    let arr1Element1ExistInArray2 = false;
    for (let j = 0; j < arr2.length; j++) {
      const arr2Element = arr2[j];
      // Compares both
      let isSame = true;
      for (let k = 0; k < allKeys.length; k++) {
        const key = allKeys[k];
        if (arr1Element[key] !== arr2Element[key]) isSame = false;
      }
      if (isSame) arr1Element1ExistInArray2 = true;
    }
    if (!arr1Element1ExistInArray2) result.push(arr1Element);
  }
  return result;
};

const AutocompleteInput = ({
  options = [],
  value = '' | [],
  label = 'Select value',
  placeholder = 'Search',
  multiple = false,
  freeSolo = false,
  onValueChanges,
  disableSearch = false,
  loading = false,
  loadingBlocksOptions = false,
  selectAll = false,
  helperText,
  getOptionLabel = option => option,
  isOptionEqualToValue = (option, value) => option === value,
  insideButton,
  timesInputClosed,
  selectAllByGroup = false,
  showScrollbar = false,
  ...others
}) => {
  // Close sstuff
  const autocompleteReff = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleCloseAutoComplete = () => {
    setOpen(false);
  };

  const handleOpenAutoComplete = () => {
    setOpen(true);
  };
  //
  // After generate table
  useEffect(() => {
    if (timesInputClosed > 0) {
      setOpen(false);
    }
  }, [timesInputClosed]);

  //
  const allSelected = options.length === value.length;
  const filter = createFilterOptions();

  const handleChangeWithAll = (event, selectedOptions, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOptions.find(option => option.type === 'Select All')) {
        if (allSelected) {
          return onValueChanges([]);
        } else {
          let result = [];
          result = options.filter(el => el.type !== 'Select All');
          return onValueChanges(result);
        }
      } else {
        return onValueChanges(selectedOptions);
      }
    } else if (reason === 'clear') {
      return onValueChanges([]);
    }
  };

  const handleChange = (event, selectedOptions) => {
    if (onValueChanges) onValueChanges(selectedOptions);
  };

  const handleChangeSelect = event => {
    if (onValueChanges) onValueChanges(event);
  };

  const OptionsGroupButtonSelect = groupData => (
    <Button
      text={'Select all'}
      type={'primaryLight'}
      rounded={'small'}
      size={'xsmall'}
      onClick={() => {
        const unique = [...value, ...groupData.groupData].filter(
          (value, index, self) =>
            index ===
            self.findIndex(t => t.name === value.name && t.npn === value.npn)
        );
        onValueChanges(unique);
      }}
    />
  );
  const OptionsGroupButtonUnselect = groupData => (
    <Button
      text={'Unselect all'}
      type={'secondary'}
      rounded={'small'}
      size={'xsmall'}
      onClick={() =>
        onValueChanges(filterByAllKeys(value, groupData.groupData))
      }
    />
  );
  const handleRenderGroup = ({ group, children }) => {
    return (
      <div key={group}>
        <div
          style={{
            height: group ? 60 : 0,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
              color: '#888',
              fontSize: 14
            }}
          >
            {group}
          </div>
          {selectAllByGroup && group !== undefined && group !== 'Main Agent' ? (
            <div style={{ display: 'flex', gap: '10px' }}>
              <OptionsGroupButtonSelect
                groupData={children.map(c => JSON.parse(c.key))}
              />
              <OptionsGroupButtonUnselect
                groupData={children.map(c => JSON.parse(c.key))}
              />
            </div>
          ) : null}
        </div>
        <div>
          {children.map((child, index) => (
            <div key={index}>{child}</div>
          ))}
        </div>
      </div>
    );
  };

  const handleRenderOption = (props, option, { selected }) => {
    const selectAllProps =
      option.type === 'Select All' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <li
        {...props}
        key={JSON.stringify(option)}
        style={{
          pointerEvents: loading && loadingBlocksOptions ? 'none' : 'auto'
        }}
      >
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {typeof option === 'object' ? option.name : option}
      </li>
    );
  };

  const renderInput = params => {
    return (
      <TextField
        {...params}
        label={label}
        placeholder={placeholder}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color='inherit' size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          )
        }}
      />
    );
  };

  return (
    <div
      className={`${styles.autocomplete} ${
        showScrollbar && styles.showScrollbar
      }`}
    >
      {disableSearch ? (
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id={others.id}>{label}</InputLabel>
          <Select
            labelId={others.id}
            id={others.id}
            value={value}
            label={label}
            placeholder={placeholder}
            onChange={handleChangeSelect}
            SelectDisplayProps={{
              style: {
                height: '1.4375em'
              }
            }}
            {...others}
          >
            {options.map(name => {
              return (
                <MenuItem
                  key={name}
                  value={name}
                  // style={getStyles(name, status, theme)}
                >
                  {name}
                </MenuItem>
              );
            })}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      ) : (
        <Autocomplete
          ref={autocompleteReff}
          onClose={() => handleCloseAutoComplete()}
          onOpen={() => handleOpenAutoComplete()}
          loading={loading}
          loadingText={'Loading...'}
          multiple={multiple}
          freeSolo={freeSolo}
          // size='small'
          value={value}
          id='autocompletetags'
          options={options}
          disableCloseOnSelect
          getOptionLabel={getOptionLabel}
          // groupBy={groupBy}
          isOptionEqualToValue={isOptionEqualToValue}
          renderOption={handleRenderOption}
          style={{
            width: '100%'
          }}
          disablePortal
          renderInput={renderInput}
          renderTags={(value, getTagProps) => (
            <>
              <div
                style={{
                  maxHeight: '150px',
                  maxWidth: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  overflowY: 'auto'
                }}
              >
                {value.map((option, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex'
                    }}
                  >
                    {index === 0 ? insideButton : <></>}
                    <Chip
                      size='medium'
                      sx={ChipStyles}
                      label={typeof option === 'object' ? option.name : option}
                      {...getTagProps({ index })}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          renderGroup={handleRenderGroup}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (selectAll)
              return [{ name: 'Select All', type: 'Select All' }, ...filtered];
            else return filtered;
          }}
          onChange={selectAll ? handleChangeWithAll : handleChange}
          open={open}
          {...others}
        />
      )}
    </div>
  );
};

AutocompleteInput.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  freeSolo: PropTypes.bool,
  onValueChanges: PropTypes.func,
  disableSearch: PropTypes.bool,
  loading: PropTypes.bool,
  loadingBlocksOptions: PropTypes.bool,
  selectAll: PropTypes.bool,
  helperText: PropTypes.string,
  groupBy: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  insideButton: PropTypes.any,
  timesInputClosed: PropTypes.number,
  selectAllByGroup: PropTypes.bool,
  showScrollbar: PropTypes.bool
};

export default AutocompleteInput;
