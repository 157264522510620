import Button from 'components/Button/Button';
import { buttonStyling } from 'constants/enum';
import React from 'react';
import style from './ModalActionConfirmation.module.scss';
import propTypes from 'prop-types';

function ModalActionConfirmation({ openPrimaryModal, closeModal, modalText }) {
  const onConfirm = () => {
    closeModal();
    openPrimaryModal();
  };
  return (
    <div className={style.container}>
      <p>{modalText}</p>
      <div className={style.buttonContainer}>
        <Button
          onClick={onConfirm}
          text='Proceed'
          type='primary'
          className={`${buttonStyling.Open} ${buttonStyling.hover}`}
        />
        <Button onClick={closeModal} text='Cancel' type='danger' />
      </div>
    </div>
  );
}

ModalActionConfirmation.propTypes = {
  closeModal: propTypes.func,
  modalText: propTypes.string,
  openPrimaryModal: propTypes.func
};

export default ModalActionConfirmation;
