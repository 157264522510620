import firebase from 'firebase/compat/app';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function useCodeValidator(
  verificationId,
  resolver = null,
  currentCredentials = null,
  newPhoneUpdate = false
) {
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [userCredentials, setUserCredentials] = useState(null);

  async function validate() {
    console.log('resolver');
    console.log(resolver);
    setLoading(true);
    setError('');
    try {
      const cred = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        code
      );
      const mfAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

      if (resolver) {
        const firebaseData = await resolver.resolveSignIn(mfAssertion);
        // console.log(firebaseData);
        setUserCredentials(firebaseData);
      } else {
        await firebase
          .auth()
          .currentUser.multiFactor.enroll(mfAssertion, 'User phone number');

        if (newPhoneUpdate) {
          navigate('/');
        }
        if (currentCredentials) {
          setUserCredentials(currentCredentials);
        }
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  return { error, validate, code, setCode, loading, userCredentials };
}

export default useCodeValidator;
