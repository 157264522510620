import React, { useEffect, useState } from 'react';
import VerificationCode from 'views/MFA/CodeVerification/VerificationCode';
import PhoneNumberInput from 'views/MFA/PhoneNumberInput/PhoneNumberInput';
import Reauthenticate from 'views/Reauthenticate/Reauthenticate';
import ChangePassword from './ChangePassword';

function ChangePasswordMFA() {
  
  const [resolver, setResolver] = useState(null);
  const [userCredentials, setUserCredentials] = useState(null);
  const [verificationId, setVerificationId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    console.log(userCredentials);
  }, [userCredentials]);

  return (
    userCredentials ? 
    <ChangePassword
      userCredentials={userCredentials}
      setResolver={setResolver}
      setUserCredentials={setUserCredentials}
    />
    :
    (!resolver && !userCredentials)
      ? <Reauthenticate 
        setResolver={setResolver} 
        setUserCredentials={setUserCredentials} 
      />
      : verificationId === ''
        ? <PhoneNumberInput
          resolver={resolver}
          setVerificationId={setVerificationId}
          setPhoneNumber={setPhoneNumber}
        />
        : <VerificationCode 
          resolver={resolver} 
          verificationId={verificationId} 
          phoneNumber={phoneNumber}
          setUserCredentials={setUserCredentials}
        />
  );

}

export default ChangePasswordMFA;