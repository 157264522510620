import firebase from 'firebase/compat/app';
import getCompanyVariables from 'helpers/getCompanyVariables';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const company = getCompanyVariables();

const PrivateRoute = () => {
  const authenticated = useSelector(
    state => state.authentication.authenticated
  );
  const zohoId = useSelector(state => state.user.zohoId);

  React.useEffect(() => {
    if (company !== 'oneill') return;

    const fetchUser = async () => {
      const multifactors =
        firebase.auth()?.currentUser?.multiFactor?.enrolledFactors;
      if (!multifactors || multifactors.length === 0) {
        firebase.auth().signOut();
      }
    };

    fetchUser();
  }, []);

  return authenticated && zohoId ? (
    <Outlet />
  ) : (
    <Navigate to={'/login'} replace />
  );
};

export default PrivateRoute;
