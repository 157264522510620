import endpoints from 'constants/endpoints';
import { apptStatus, bobStatus, stateAbbreviations } from 'constants/enum';
import {
  IS_DOWNLOADING_REPORT,
  UPDATE_AGENT_POLICIES_COUNT,
  UPDATE_AGENT_POLIICES_ARRAY
} from 'constants/types';
import { safeAWSCallPost } from 'helpers/firebaseSetup';
import { showAlert } from './site';

// action creators
export const setIsDownloadingReport = payload => {
  return {
    type: IS_DOWNLOADING_REPORT,
    payload
  };
};

export const getHStabData =
  (
    setLocalLoader = () => {},
    cancelTokenSource = {},
    policies_per_page = 0,
    page = 0,
    filters = {},
    agents = []
  ) =>
  async dispatch => {
    const page_for_db = page - 1;
    safeAWSCallPost(
      endpoints.aws.HStabdata,
      {
        policies_per_page: policies_per_page,
        page: page_for_db,
        filters: JSON.stringify(filters),
        agents
      },
      false,
      cancelTokenSource
    )
      .then(async HStabdata => {
        console.log('HStabdata response');
        console.log(HStabdata.data);
        const policies = HStabdata.data.policies_HS_full;

        await dispatch({
          type: UPDATE_AGENT_POLIICES_ARRAY,
          payload: policies
        });

        if (!filters.skipCountPages) {
          const totalPossibleRows = HStabdata.data.totalUserRows;
          await dispatch({
            type: UPDATE_AGENT_POLICIES_COUNT,
            payload: totalPossibleRows
          });
        }
        setLocalLoader(false);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setLocalLoader(false);
      });
  };

export const downloadAllCustomersData = data => dispatch => {
  // Procedures
  const wait = t => {
    return new Promise(resolve => {
      setTimeout(() => resolve(), t);
    });
  };
  const showErrorAlert = msg => {
    dispatch(setIsDownloadingReport(false));
    dispatch(
      showAlert({
        message: msg,
        code: 'error'
      })
    );
  };
  const catchError = async err => {
    console.log('catched function');
    console.log(err);

    await dispatch(
      showAlert({
        message:
          'Something went wrong downloading customers data. Try again later.',
        code: 'error'
      })
    );
    await wait(700);
    await dispatch(setIsDownloadingReport(false));
  };
  const userDonwloadCsv = async fileData => {
    // generate date for file name
    let fileDate = new Date()
      .toISOString()
      .replace(/T/, '_') // replace T with a space
      .replace(/:/g, '_') // replace : with
      .replace(/\..+/, ''); // delete the dot and everything after

    await downloadCSV(
      `Customers_Data_${fileDate}.csv`,
      fileData
      // response.data.file
    );
    dispatch(
      showAlert({
        message: 'Data downloaded successfully!',
        code: 'success'
      })
    );
    dispatch(setIsDownloadingReport(false));
  };
  const showDownloadingAlert = () => {
    dispatch(setIsDownloadingReport(true));
    dispatch(showAlert({ message: 'Running customers', code: 'info' }));
  };
  showDownloadingAlert();

  safeAWSCallPost(
    endpoints.aws.HStabdataCsv,
    {
      filters: JSON.stringify({
        ...data,
        mktp_state: data.mktp_state.map(state => stateAbbreviations[state]),
        agents: data.agents.map(agent => agent.npn.toString()),
        carrierApptStatus: data.carrierApptStatus.map(
          value => apptStatus[value]
        ),
        bobFilter: data.bobFilter.map(value => bobStatus[value])
      })
    },
    false
  )
    .then(async response => {
      const data =
        typeof response.data === 'string'
          ? await JSON.parse(response.data)
          : response.data;
      if (!data.ok) {
        showErrorAlert(data.message);
        return;
      }
      const build_csv_string = data.file.replaceAll('JUMPLINE', '\n');
      userDonwloadCsv(build_csv_string);
    })
    .catch(error => catchError(error));
};

export const downloadAllBOBData = data => dispatch => {
  // Procedures
  const wait = t => {
    return new Promise(resolve => {
      setTimeout(() => resolve(), t);
    });
  };
  const showErrorAlert = msg => {
    dispatch(setIsDownloadingReport(false));
    dispatch(
      showAlert({
        message: msg,
        code: 'error'
      })
    );
  };
  const catchError = async err => {
    console.log('catched function');
    console.log(err);

    await dispatch(
      showAlert({
        message:
          'Something went wrong downloading customers data. Try again later.',
        code: 'error'
      })
    );
    await wait(700);
    await dispatch(setIsDownloadingReport(false));
  };
  const userDonwloadCsv = async fileData => {
    // generate date for file name
    let fileDate = new Date()
      .toISOString()
      .replace(/T/, '_') // replace T with a space
      .replace(/:/g, '_') // replace : with
      .replace(/\..+/, ''); // delete the dot and everything after

    await downloadCSV(
      `BOB_Data_${fileDate}.csv`,
      fileData
      // response.data.file
    );
    dispatch(
      showAlert({
        message: 'Data downloaded successfully!',
        code: 'success'
      })
    );
    dispatch(setIsDownloadingReport(false));
  };
  const showDownloadingAlert = () => {
    dispatch(setIsDownloadingReport(true));
    dispatch(showAlert({ message: 'Running customers', code: 'info' }));
  };
  showDownloadingAlert();

  safeAWSCallPost(
    endpoints.aws.BOBtabdataCsv,
    {
      filters: JSON.stringify({
        ...data,
        agents: data.agents.map(agent => agent.npn)
      }),
      agents: data.agents.map(agent => agent.id.toString())
    },
    false
  )
    .then(async response => {
      const data =
        typeof response.data === 'string'
          ? await JSON.parse(response.data)
          : response.data;
      if (!data.ok) {
        showErrorAlert(data.message);
        return;
      }
      const build_csv_string = data.file.file.replaceAll('JUMPLINE', '\n');
      userDonwloadCsv(build_csv_string);
    })
    .catch(error => catchError(error));
};

async function downloadCSV(filename, data) {
  var blob = new Blob([data]);
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = await filename;
  link.click();
}

export const ignoreCarrier =
  (
    setLocalLoader = () => {},
    cancelTokenSource = {},
    carrier_id,
    data,
    unignore
  ) =>
  async dispatch => {
    safeAWSCallPost(
      unignore
        ? endpoints.aws.HSunignoreCarrier
        : endpoints.aws.HSignoreCarrier,
      {
        carrier_id,
        ...data
      },
      false,
      cancelTokenSource
    )
      .then(async HStabdata => {
        console.log('HSun/ignoreCarrier response');
        console.log(HStabdata.data);
        setLocalLoader(false);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        dispatch(
          showAlert({
            message: 'Could not un/ignore carrier',
            code: 'error'
          })
        );
        setLocalLoader(false);
      });
  };

export const revalidatePolicy =
  (setLocalLoader = () => {}, cancelTokenSource = {}, policy_id, data) =>
  async dispatch => {
    safeAWSCallPost(
      endpoints.aws.HSRevalidateStatuses,
      {
        hsPolicyId: policy_id,
        ...data
      },
      false,
      cancelTokenSource
    )
      .then(async HStabdata => {
        console.log('HSun/revalidate response');
        console.log(HStabdata.data);
        setLocalLoader(false);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        dispatch(
          showAlert({
            message: 'Could not revalidate statuses',
            code: 'error'
          })
        );
        setLocalLoader(false);
      });
  };
export const ignorePolicy =
  (
    setLocalLoader = () => {},
    cancelTokenSource = {},
    policy_id,
    data,
    unignore
  ) =>
  async dispatch => {
    safeAWSCallPost(
      unignore ? endpoints.aws.HSunignorePolicy : endpoints.aws.HSignorePolicy,
      {
        policy_id,
        ...data
      },
      false,
      cancelTokenSource
    )
      .then(async HStabdata => {
        console.log('HSun/ignorePolicy response');
        console.log(HStabdata.data);
        setLocalLoader(false);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        dispatch(
          showAlert({
            message: 'Could not un/ignore carrier',
            code: 'error'
          })
        );
        setLocalLoader(false);
      });
  };

export const carrierInProgress =
  (setLocalLoader = () => {}, cancelTokenSource = {}, carrier_id, states) =>
  async dispatch => {
    safeAWSCallPost(
      endpoints.aws.HScarrierInProgress,
      {
        carrier_id,
        states: states.map(state => stateAbbreviations[state])
      },
      false,
      cancelTokenSource
    )
      .then(async HStabdata => {
        console.log('HSCarrierInProgress response');
        console.log(HStabdata.data);
        setLocalLoader(false);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        dispatch(
          showAlert({
            message: 'Could not put carrier in progress',
            code: 'error'
          })
        );
        setLocalLoader(false);
      });
  };

export const emailedCarrierPolicy =
  (setLocalLoader = () => {}, cancelTokenSource = {}, data) =>
  async dispatch => {
    safeAWSCallPost(
      endpoints.aws.HSemailedCarrierPolicy,
      {
        ...data
      },
      false,
      cancelTokenSource
    )
      .then(async HStabdata => {
        console.log('HSemailedCarrierPolicy response');
        console.log(HStabdata.data);
        setLocalLoader(false);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        dispatch(
          showAlert({
            message: 'Could not email carrier',
            code: 'error'
          })
        );
        setLocalLoader(false);
      });
  };
