import style from 'components/Button/button.module.scss';
import getCompanyVariables from 'helpers/getCompanyVariables';

export const buttonStyling = {
  Open: style.open,
  Self: style.self,
  "O'Neill": style.oNeill,
  'In Review': style.inReview,
  Closed: style.closed,
  hover: style.hoverable
};

export const apptStatus = {
  Appointed: 'Appointed',
  'Not Appointed': 'NotAppointed',
  Ignored: 'Ignore',
  'N/A': 'N/A'
};

export const bobStatus = {
  Matched: 'Matched',
  '>2 Weeks Past': 'TwoWeeksPast',
  'Pending Match': 'PendingMatch',
  Ignored: 'Ignore',
  'Check AOR': 'AssignedToOther',
  'Manually Unmatched': 'ManuallyUnmatched',
  'Manually Matched': 'ManuallyMatched'
};

export const stateAbbreviations = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  'Kansas City': 'KSC'
};

export const STATE_LIST = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

export const STATE_LIST_CERT = [
  {
    state: 'Arkansas',
    instructions: [
      'AR requires agents to add a line of authority on their AR license. To do this please go <a target="_blank" href="https://nipr.com/licensing-center/add-a-line-of-authority"> https://nipr.com/licensing-center/add-a-line-of-authority</a>. Agents will also need to do training at: <a target="_blank" href="https://training.myarinsurance.com/">https://training.myarinsurance.com/</a>'
    ]
  },
  {
    state: 'California',
    instructions: [
      '- For Individual Agents – Email <a href="mailto:louise@oneillmarketing.net">louise@oneillmarketing.net</a> and request a CoveredCA account.',
      '- For Agencies - <a target="_blank" href="https://coveredca.com/agents/steps/agencies-of-one-or-more/"> https://coveredca.com/agents/steps/agencies-of-one-or-more/</a>  (additional training required).'
    ]
  },
  {
    state: 'Colorado',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://connectforhealthco.com/for-certified-brokers/">https://connectforhealthco.com/for-certified-brokers/</a>.'
    ]
  },
  {
    state: 'Connecticut',
    instructions: [
      'Request an agent certification training account by emailing:  <a href="mailto:BrokerRegistration.AHCT@ct.gov">BrokerRegistration.AHCT@ct.gov</a>.'
    ]
  },
  {
    state: 'Idaho',
    instructions: [
      'Request an agent certification training account by emailing: <a href="mailto:connectors@yourhealthidaho.org">connectors@yourhealthidaho.org</a>.'
    ]
  },
  {
    state: 'Kentucky',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://kyhbe.ky.gov/">https://kyhbe.ky.gov/</a> . Agent Welcome Packet and Instructions: <a target="_blank" href="https://khbe.ky.gov/Agents-kynectors/Documents/kynectorWelcomePacket.pdf">https://khbe.ky.gov/Agents-kynectors/Documents/kynectorWelcomePacket.pdf</a>.',
      'Help Desk: 502-564-0104'
    ]
  },
  {
    state: 'Virginia',
    instructions: [
      'To get certified, follow the instructions found on Virginia\'s website, or email   <a rel="noopener noreferrer" href="mailto:ExchangeAgents@scc.virginia.gov" style="text-decoration-line:none;outline:none 0px;font-size:medium;font-family:Puvi,-apple-system,system-ui,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,&quot;Helvetica Neue&quot;,Arial,sans-serif" target="_blank">ExchangeAgents@scc.virginia.gov</a> for information.  You can find the VA SBE FAQs <a target="_blank" href="https://www.scc.virginia.gov/getdoc/04696b64-deae-4431-bd36-0a355ad84644/HBE-Agent-FAQs">at this link.</a>'
    ]
  },
  {
    state: 'Maryland',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://mhbe.force.com/training/CustomCommunityLogin">https://mhbe.force.com/training/CustomCommunityLogin</a>.'
    ]
  },
  {
    state: 'Massachusetts',
    instructions: [
      'Massachusetts does not work with agents to sell individual health plans.'
    ]
  },
  {
    state: 'Minnesota',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://www.mnsure.org/about-us/assister-program/agents-brokers/index.jsp">https://www.mnsure.org/about-us/assister-program/agents-brokers/index.jsp</a>.'
    ]
  },
  {
    state: 'Nevada',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://www.nevadahealthlink.com/brokers-agents-onboarding/">https://www.nevadahealthlink.com/brokers-agents-onboarding/</a>.',
      'Nevada only allows NV residents to certify.'
    ]
  },
  {
    state: 'New Jersey',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://nj.gov/getcoverednj/home/assisters_ba.shtml">https://nj.gov/getcoverednj/home/assisters_ba.shtml</a>.'
    ]
  },
  {
    state: 'New Mexico',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://www.bewellnm.com/getting-started/agents-brokers/">https://www.bewellnm.com/getting-started/agents-brokers/</a>.',
      'To request access email: <a href="mailto:Partners@nmhix.com">Partners@nmhix.com</a>'
    ]
  },
  {
    state: 'New York',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://nystateofhealth.ny.gov/agent">https://nystateofhealth.ny.gov/agent</a>.'
    ]
  },
  {
    state: 'Pennsylvania',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://agency.pennie.com/brokers/">https://agency.pennie.com/brokers/</a>.'
    ]
  },
  {
    state: 'Rhode Island',
    instructions: [
      'Request an agent certification training account by emailing: <a href="mailto:HSRIBroker@nfphealth.com">HSRIBroker@nfphealth.com</a>.'
    ]
  },
  {
    state: 'Vermont',
    instructions: [
      'Request an agent certification training account by emailing: <a href="mailto:ahs.vthealthconnect@vermont.gov">ahs.vthealthconnect@vermont.gov</a>.'
    ]
  },
  {
    state: 'Washington',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://www.wahbexchange.org/partners/insurance-brokers/">https://www.wahbexchange.org/partners/insurance-brokers/</a>.'
    ]
  },
  {
    state: 'Georgia',
    instructions: [
      'Follow instructions here: <a target="_blank" href="https://certification.georgiaaccess.gov//">https://certification.georgiaaccess.gov/</a>.'
    ]
  }
];
// 'Follow instructions here: https://dchealthlink.com/brokers'

export const USER_TYPES = {
  ADMIN: 'Admin',
  MEMBER: 'Member'
};

const company = getCompanyVariables();
let roles = {};
let types = {};
switch (company) {
  case 'oneill':
    roles = {
      superAdminId: '4738157000000297001',
      internalAgentConsultant: '4738157000134450182',
      name: 'OM Support Team',
      phone: '813-489-4156',
      email: 'support@oneillmarketing.net',
      img: 'https://firebasestorage.googleapis.com/v0/b/o-neill-marketing-portal.appspot.com/o/Employee%20Profile%20Pics%2Fuser-thumbnail(20).png?alt=media&token=a857dcc3-79f8-4783-9a95-ccfd9fcdd709'
    };
    types = {
      NewAppointment: 'New Appointment',
      DirectWithCarrier:
        "Transfer - Currently appointed direct with Carrier, Transfer to O'Neill",
      ThroughAnotherAgency:
        "Transfer - Currently appointed through another Agency, Transfer to O'Neill"
    };
    break;
  case 'sunshine':
    roles = {
      superAdminId: '6291220000000465001',
      internalAgentConsultant: '1',
      name: 'Sunshine Support Team',
      phone: '305-602-0436',
      email: 'acacontracting@sunshinelifehealth.com',
      img: 'https://firebasestorage.googleapis.com/v0/b/o-neill-marketing-portal.appspot.com/o/Employee%20Profile%20Pics%2Fuser-thumbnail(20).png?alt=media&token=a857dcc3-79f8-4783-9a95-ccfd9fcdd709'
    };
    types = {
      NewAppointment: 'New Appointment',
      DirectWithCarrier:
        'Transfer - Currently appointed direct with Carrier, Transfer to Sunshine',
      ThroughAnotherAgency:
        'Transfer - Currently appointed through another Agency, Transfer to Sunshine'
    };
    break;
}

export const companyRoles = roles;

export const transferTypes = types;

export const TRANSFER_OPTIONS = [
  transferTypes.NewAppointment,
  transferTypes.DirectWithCarrier,
  transferTypes.ThroughAnotherAgency
];
