import React from 'react';
import LoginFormContainer from 'components/LoginFormContainer/LoginFormContainer';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';
import SecondaryPage from 'components/SecondaryPage/SecondaryPage';
import PropTypes from 'prop-types';

function ChangePassword({
  userCredentials = null,
  setResolver = null,
  setUserCredentials = null,
  setVerificationId = null,
  setPhoneNumber = null,
}) {
  return (
    <SecondaryPage>
      <LoginFormContainer>
        <ChangePasswordForm
          userCredentials={userCredentials}
          setResolver={setResolver}
          setUserCredentials={setUserCredentials}
          setVerificationId={setVerificationId}
          setPhoneNumber={setPhoneNumber}
        />
      </LoginFormContainer>
    </SecondaryPage>
  );
}

ChangePassword.propTypes = {
  userCredentials: PropTypes.object,
  setResolver: PropTypes.func,
  setUserCredentials: PropTypes.func,
  setVerificationId: PropTypes.func,
  setPhoneNumber: PropTypes.func,
};

export default ChangePassword;
