import withLocalLoader from 'components/HOCs/withLocalLoader';
import { connect } from 'react-redux';
import DashboardBob from './DashboardBob';

function mapStateToProps({}) {
  return {};
}

export default connect(
  mapStateToProps,
  {}
)(withLocalLoader(DashboardBob, false, true, 'CustomersBob'));
