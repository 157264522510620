import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';

function useCodeSender(container_id, resolver = null) {
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setRecaptchaVerifier(
      new firebase.auth.RecaptchaVerifier(container_id, {
        size: 'invisible',
        callback: function () {
          setError('');
        },
        'expired-callback': function () {
          setError('Recaptcha expired');
        }
      })
    );
    return () => {
      recaptchaVerifier?.clear();
      setError('');
    };
  }, []);

  async function sendCode() {
    setError('');
    setIsLoading(true);
    try {
      let session = null;
      if (resolver) {
        session = resolver.session;
      } else {
        session = await firebase.auth().currentUser.multiFactor.getSession();
      }
      const phoneInfoOptions = {
        session: session
      };

      if (resolver && resolver.hints.length > 0) {
        phoneInfoOptions.multiFactorHint = resolver.hints.at(-1);
        setPhoneNumber(resolver.hints.at(-1).phoneNumber);
      } else {
        phoneInfoOptions.phoneNumber = phoneNumber;
      }

      const phoneProvider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await phoneProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier
      );
      setVerificationId(verificationId);
    } catch (err) {
      setError(err.message);
    }
    setIsLoading(false);
  }

  return {
    error,
    sendCode,
    verificationId,
    phoneNumber,
    setPhoneNumber,
    isLoading
  };
}

export default useCodeSender;
