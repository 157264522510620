import axios from 'axios';
import endpoints from 'constants/endpoints';
import { apptStatus, bobStatus, stateAbbreviations } from 'constants/enum';
import { safeAWSCallPost } from 'helpers/firebaseSetup';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHStabData } from 'state/actions/actions';
import { showAlert } from 'state/actions/site';

const paymentStatuses = {
  action_needed: 'Action Needed'
};

const INITIAL_VALUES = {
  searchName: '',
  carriers: [],
  agencies: [],
  agents: [],
  mktp_state: [],
  typeFilter: [],
  bobFilter: [],
  carrierApptStatus: []
};

const useCustomersData = ({
  loadingApptsData,
  setLocalLoader,
  setFilterCount,
  setShowFilters,
  setActivePanel
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [formData, setformData] = useState(INITIAL_VALUES);
  const [triggerFetch, setTriggerFetch] = useState(1);
  const [loadingPotentialMatches, setLoadingPotentialMatches] = useState(false);

  const [potentialMatchesData, setPotentialMatchesData] = useState({
    HsPolicyId: '',
    BobStatus: '',
    currentBobMatchId: '',
    npn: '',
    potentialBobMatches: []
  });

  // Loads data initially
  const cancelTokenSource = axios.CancelToken.source();

  const getHSData = React.useCallback(
    (page, filters, loadingApptsData, skipCountPages) => {
      setLocalLoader(true);
      setActivePanel({});
      dispatch(
        getHStabData(
          loadingApptsData ? () => {} : setLocalLoader,
          () => {},
          currentPageSize,
          page,
          {
            ...filters,
            mktp_state: filters.mktp_state.map(
              state => stateAbbreviations[state]
            ),
            agents: filters.agents.map(agent => agent.npn.toString()),
            carrierApptStatus: filters.carrierApptStatus.map(
              value => apptStatus[value]
            ),
            bobFilter: filters.bobFilter.map(value => bobStatus[value]),
            skipCountPages
          },
          filters.agents.map(agent => agent.id.toString())
        )
      );
      const tbodyElement = document.getElementById('customers-table-body');
      tbodyElement.scrollTop = 0;
    },
    [currentPageSize]
  );

  const getPotentialMatches = useCallback(
    async (hsPolicyId, npn, BobStatus) => {
      setLoadingPotentialMatches(true);
      safeAWSCallPost(
        endpoints.aws.HSGetPotentialBobMatches,
        {
          hsPolicyId,
          npn,
          BobStatus
        },
        false,
        cancelTokenSource
      )
        .then(async HStabdata => {
          console.log('HSGetPotential BOB matches response');
          setPotentialMatchesData(potentialMatches => {
            return {
              ...potentialMatches,
              currentBobMatchId: HStabdata.data.currentBobMatchId,
              potentialBobMatches: HStabdata.data.potentialBobMatches
            };
          });
          setLoadingPotentialMatches(false);
        })
        .catch(error => {
          console.log('error');
          console.log(error);
          setLoadingPotentialMatches(false);
          dispatch(
            showAlert({
              message: 'Could not load potential bob matches in progress',
              code: 'error'
            })
          );
        });
    },
    [potentialMatchesData.HsPolicyId]
  );

  const deleteBobMatch = useCallback(async () => {
    safeAWSCallPost(
      endpoints.aws.HSRemoveCurrentAssociation,
      {
        hsPolicyId: potentialMatchesData.HsPolicyId,
        BOB_Id: potentialMatchesData.currentBobMatchId
      },
      false,
      cancelTokenSource
    )
      .then(async HStabdata => {
        console.log('HSRemove BOB match response');
        console.log(HStabdata.data);
        setPotentialMatchesData(potentialMatches => {
          return {
            ...potentialMatches,
            BobStatus: 'ManuallyUnmatched',
            currentBobMatchId: ''
          };
        });
        getPotentialMatches(
          potentialMatchesData.HsPolicyId,
          potentialMatchesData.npn,
          'ManuallyUnmatched'
        );
        setTriggerFetch(num => num + 1);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        dispatch(
          showAlert({
            message: 'Could not remove bob match',
            code: 'error'
          })
        );
      });
  }, [setPotentialMatchesData, potentialMatchesData, getPotentialMatches]);

  const confirmBobMatch = useCallback(
    async (bobNpn, bobId) => {
      safeAWSCallPost(
        endpoints.aws.HSConfirmCurrentAssociation,
        {
          hsPolicyId: potentialMatchesData.HsPolicyId,
          npn: potentialMatchesData.npn,
          BOB_Id: bobId,
          BOB_Npn: bobNpn
        },
        false,
        cancelTokenSource
      )
        .then(async HStabdata => {
          console.log('HSRemove BOB match response');
          console.log(HStabdata.data);
          setPotentialMatchesData(potentialMatches => {
            return {
              ...potentialMatches,
              BobStatus: 'ManuallyMatched',
              currentBobMatchId: bobId
            };
          });
          getPotentialMatches(
            potentialMatchesData.HsPolicyId,
            potentialMatchesData.npn,
            'ManuallyUnmatched'
          );
          setTriggerFetch(num => num + 1);
        })
        .catch(error => {
          console.log('error');
          console.log(error);
          dispatch(
            showAlert({
              message: 'Could not remove bob match',
              code: 'error'
            })
          );
        });
    },
    [setPotentialMatchesData, potentialMatchesData, getPotentialMatches]
  );

  React.useEffect(() => {
    if (formData.agents.length === 0) return;
    // HS data
    getHSData(currentPage, formData, loadingApptsData, true);

    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, [triggerFetch]);

  // Raw policies data
  const { policies, agent_policies_count } = useSelector(
    state => state.healthSherpaData
  );

  // Transform data to fit into the table
  const data_for_table = React.useMemo(() => {
    console.log('===============================================');
    console.log('========= POLICIES HAVE CHANGED ONCE ==========');
    console.log('===============================================');

    return policies.map(x => {
      const paymentSt = x?.followups?.length;

      const { residence, assistor } = x;

      return {
        ...x,
        HealthSherpaId: x.Policies_healthSherpaId,
        Client: `${x.demographics_firstName || ''} ${
          x.demographics_middleName || ''
        } ${x.demographics_lastName || ''}`,
        Carrier: x.issuer_name,
        CarrierPolicyNumber: x.mktp_issuer_assigned_policy_id || '-',
        State: x.mktp_state,
        CarrierId: x.carrier_id,
        Created: x.mktp_created_at,
        Effective: x.mktp_effective_date,
        Plan: x.plan_name,
        Gross: x.grossPremium,
        Net: x.netPremium,
        Payment: paymentSt ? paymentStatuses[x.followups[0].status] : 'Unknown',
        CarrierApptStatus: x?.carrier_audit_status || 'NotAppointed',
        BobStatus: x?.bob_status || 'PendingMatch',
        Residence: `${residence?.streetAddress}, ${residence?.zipCode} ${residence?.county}, ${residence?.city}`,
        AssistorNpn: x?.assistor_nationalProducerNumber,
        AssistorName: `${assistor?.agentBrokerFirstName} ${assistor?.agentBrokerLastName}`,
        AssistorAgency: assistor?.agency_name
      };
    });
  }, [policies]);

  const handleChangeSelectValue = useCallback(
    (_values, property) => {
      let values = [];
      if (typeof _values === 'string') {
        values = formData[property][0] === _values ? [] : [_values];
      } else {
        values = _values;
      }
      if (
        formData[property].length === 0 &&
        values.length > 0 &&
        property !== 'agents'
      ) {
        setFilterCount(count => (count += 1));
      } else if (
        formData[property].length > 0 &&
        values.length === 0 &&
        property !== 'agents'
      ) {
        setFilterCount(count => (count -= 1));
      }
      setformData(prevState => ({
        ...prevState,
        [property]: values
      }));
    },
    [setformData, formData, setFilterCount]
  );

  const clearFilters = useCallback(() => {
    setFilterCount(0);
    setformData(_formdata => {
      return {
        ...INITIAL_VALUES,
        agents: [..._formdata.agents]
      };
    });
  }, [setFilterCount, setformData]);

  const applyFilters = useCallback(() => {
    if (formData.agents.length === 0) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    getHSData(currentPage, formData, false, false);
    setShowFilters(false);
  }, [setCurrentPage, currentPage, getHSData, formData]);

  return {
    data_for_table,
    currentPage,
    setCurrentPage,
    setCurrentPageSize,
    agent_policies_count,
    formData,
    setformData,
    clearFilters,
    handleChangeSelectValue,
    applyFilters,
    setTriggerFetch,
    setPotentialMatchesData,
    potentialMatchesData,
    loadingPotentialMatches,
    deleteBobMatch,
    getPotentialMatches,
    confirmBobMatch
  };
};

useCustomersData.propTypes = {
  loadingApptsData: PropTypes.func,
  setLocalLoader: PropTypes.func,
  setFilterCount: PropTypes.func,
  setShowFilters: PropTypes.func,
  setActivePanel: PropTypes.func
};

export default useCustomersData;
