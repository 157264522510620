import Loader from 'components/Loader/Loader';
import firebase from 'firebase/compat/app';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import { LogOut } from 'state/actions/actions';
import { getNotifications } from 'state/actions/notifications';
import ActionItems from 'views/ActionItems';
import AgencyManagement from 'views/AgencyManagement';
import AgencyDashboard from 'views/AgencyManagement/AgencyDashboard';
import AgencySettings from 'views/AgencyManagement/AgencySettings';
import AgentInfo from 'views/AgentInfo';
import CarrierResources from 'views/CarrierResources';
import Commissions from 'views/Commissions';
import Dashboard from 'views/Dashboard';
import FAQs from 'views/FAQs';
import Files from 'views/Files';
import Leads from 'views/Leads';
import Marketing from 'views/Marketing';
import MyAppointmentsView from 'views/MyAppointments';
import Registration from 'views/Registration/Registration';
import ResetPassword from 'views/ResetPassword';
import Videos from 'views/Videos';
import PrivateRouteAuth from './PrivateRouteAuth';

import ACA2024 from 'views/ACA2024';
import ChangePasswordMFA from 'views/ChangePassword/ChangePasswordMFA';
import ChangePhoneMFA from 'views/ChangePhone/ChangePhoneMFA';
import DashboardBob from 'views/DashboardBob';
import DashboardCustomers from 'views/DashboardCustomers';
import LoginMFA from 'views/Login/LoginMFA';
import OMOfferings from 'views/OMOfferings/OMOfferings';

function RoutesComponent() {
  const isLoadingGlobal = useSelector(state => state.site.loadingGlobal);
  const agencyData = useSelector(state => state.user.zohoData.agencyData);
  const [isLoadingUser, setIsLoadingUser] = React.useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async user => {
      if (!user) {
        dispatch(LogOut());
        setIsLoadingUser(false);
      } else {
        if (agencyData !== null) {
          dispatch(getNotifications());
        } else {
          dispatch(LogOut());
        }
        setIsLoadingUser(false);
      }
    });
  }, []);

  return (
    <>
      {isLoadingGlobal || isLoadingUser ? (
        <Loader global />
      ) : (
        <Router>
          <Routes>
            <Route element={<PrivateRouteAuth />}>
              <Route path='/login' element={<LoginMFA />} />
              <Route path='/resetPassword' element={<ResetPassword />} />
              <Route path='/registration' element={<Registration />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path='/' element={<Dashboard />} />
              <Route path='/ACA2024/' element={<ACA2024 />} />
              <Route path='/OMOfferings/' element={<OMOfferings />} />
              <Route
                path='/customers/policies'
                element={<DashboardCustomers />}
              />
              <Route
                path='/customers/bookOfBusiness'
                element={<DashboardBob />}
              />
              <Route path='/carrierResources' element={<CarrierResources />} />
              <Route path='/commissions/:type' element={<Commissions />} />
              <Route path='/files/:type' element={<Files />} />
              <Route path='/leads' element={<Leads />} />
              <Route path='/actionItems' element={<ActionItems />} />
              <Route path='/agencies'>
                <Route index element={<AgencyManagement />} />
                <Route
                  exact
                  path='/agencies/:id'
                  element={<AgencyDashboard />}
                />
              </Route>
              <Route
                exact
                path='/agencies/:id/settings'
                element={<AgencySettings />}
              />
              <Route path='/agentInfo' element={<AgentInfo />} />
              <Route path='/myAppointments' element={<MyAppointmentsView />} />
              <Route path='/marketing' element={<Marketing />} />
              <Route path='/videos' element={<Videos />} />
              <Route path='/changePassword' element={<ChangePasswordMFA />} />
              <Route path='/change2FaPhone' element={<ChangePhoneMFA />} />
              <Route path='/faqs' element={<FAQs />} />
            </Route>
            <Route path='*' element={<Navigate to={'/'} replace />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default RoutesComponent;
