import React from 'react';
import style from './button.module.scss';
import propTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

const buttonTypes = {
  primary: style.primary,
  primaryDarkened: style.primaryDarkened,
  primaryLight: style.primaryLight,
  secondary: style.secondary,
  transparent: style.transparent,
  danger: style.danger,
  success: style.open,
  default: style.default
};

const buttonSizes = {
  xsmall: style.xsmall,
  small: style.small,
  medium: style.medium,
  large: style.large
};
const roundedSizes = {
  small: style.roundedSmall,
  medium: style.roundedMedium,
  large: style.roundedLarge
};

function Button({
  className,
  text,
  type = 'primary',
  size = 'medium',
  onClick = () => {},
  icon,
  rounded,
  outlined = false,
  fill,
  isLoading = false,
  isDisabled = false,
  ...buttonProps
}) {
  return (
    <button
      disabled={isDisabled}
      className={`${style.button} ${buttonTypes[type]} ${buttonSizes[size]} ${
        roundedSizes[rounded]
      } ${fill ? style.fill : ''}  ${className} ${
        outlined ? style.outlined : ''
      } ${icon && !text ? style.onlyIcon : ''}`}
      onClick={onClick}
      {...buttonProps}
    >
      {}
      {isLoading ? <CircularProgress size={'small'} /> : icon && icon}
      {text && <div className={style.text}>{text}</div>}
    </button>
  );
}

Button.propTypes = {
  className: propTypes.string,
  text: propTypes.string,
  type: propTypes.string,
  size: propTypes.string,
  rounded: propTypes.string,
  outlined: propTypes.bool,
  fill: propTypes.bool,
  isLoading: propTypes.bool,
  isDisabled: propTypes.bool,
  onClick: propTypes.func,
  icon: propTypes.element
};

export default Button;
