import endpoints from 'constants/endpoints';
import userFactory from 'helpers/factory/userFactory';
import { safePostCall } from 'helpers/firebaseSetup';

export const getUser = async (firebaseData) => {
  const zohoRes = await safePostCall(endpoints.awsProxy.genericPost, {
    googleId: firebaseData.user.uid,
    zohoId: false,
    endpoint: endpoints.zoho.getAgent
  });
  const zohoData = JSON.parse(zohoRes.data.details.output);
  let folders = await getSharedFolder(zohoData.id);
  let agentPhoto = folders[2] === 'null' ? '' : folders[2];
  return {
    data: userFactory(zohoData, firebaseData, agentPhoto, folders),
    mainZohoId: zohoData.id
  };
};

export async function getSharedFolder(zohoId) {
  return safePostCall(endpoints.awsProxy.genericPost, {
    endpoint: endpoints.zoho.getSharedFoldersAndUserPhoto,
    agentId: zohoId
  }).then(response => {
    return response.data.details.output.split(',');
  });
}