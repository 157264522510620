import { Grid, TextField } from '@mui/material';
import Button from 'components/Button/Button';
import icons from 'constants/icons';
import useBobData from 'hooks/useBobData';
import PropTypes from 'prop-types';
import React from 'react';
import BobTable from './BobTable/BobTable';
import BobTableFilters from './BobTableFilters/BobTableFilters';
import style from './DashboardCustomerBob.module.scss';

const ArrowRight = icons['arrowRight'];
const SearchIcon = icons['search'];

const DashboardCustomers = ({ setLocalLoader }) => {
  // Local State
  const [showFilters, setShowFilters] = React.useState(false);
  const [filterCount, setFilterCount] = React.useState(0);
  const [activePanel, setActivePanel] = React.useState({});

  const {
    data_for_table,
    currentPage,
    setCurrentPage,
    setCurrentPageSize,
    bob_records_count,
    formData,
    setformData,
    clearFilters,
    applyFilters,
    handleChangeSelectValue,
    setTriggerFetch,
    carriersOptions
  } = useBobData({
    setLocalLoader,
    setFilterCount,
    setShowFilters,
    setActivePanel
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      applyFilters();
    }, 800);

    return () => clearTimeout(timeoutId);
  }, [formData.searchName]);

  return (
    <>
      <Grid
        container
        columnSpacing={{ xs: 1 }}
        rowSpacing={{ xs: 1 }}
        marginTop={2}
        paddingTop={1}
      >
        <Grid
          item
          xs={showFilters ? 12 : 0}
          lg={showFilters ? 4 : 0}
          xl={showFilters ? 3 : 0}
          className={style.filtersContainerMain}
        >
          <BobTableFilters
            formData={formData}
            showFilters={showFilters}
            setformData={setformData}
            clearFilters={clearFilters}
            applyFilters={applyFilters}
            handleChangeSelectValue={handleChangeSelectValue}
            filterCount={filterCount}
            carriersOptions={carriersOptions}
          />
        </Grid>
        <Grid item xs={12} lg={showFilters ? 8 : 12} xl={showFilters ? 9 : 12}>
          <div className={style.tableContainer}>
            <Grid container padding={2} rowSpacing={{ xs: 1 }}>
              <Grid item xs={12} sm={4}>
                <Button
                  text={`${
                    showFilters ? 'Hide Filters' : 'Show Filters'
                  } (${filterCount})`}
                  size='medium'
                  className={style.buttonNoWrap}
                  type='primaryDarkened'
                  icon={
                    <ArrowRight
                      className={
                        showFilters
                          ? style.arrowRightTransitionActive
                          : style.arrowRightTransition
                      }
                    />
                  }
                  onClick={() => setShowFilters(!showFilters)}
                />
              </Grid>
              <Grid item xs={12} sm={8} display='flex'>
                <TextField
                  fullWidth
                  size='small'
                  id='outlined-search'
                  label='Search for a holder'
                  value={formData.searchName}
                  onChange={e => {
                    setformData(_formData => ({
                      ..._formData,
                      searchName: e.target.value
                    }));
                  }}
                  type='search'
                  InputProps={{
                    sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                  }}
                />
                <Button
                  text='Search'
                  type='primary'
                  size='medium'
                  className={style.searchButton}
                  onClick={() => applyFilters()}
                  icon={<SearchIcon />}
                />
              </Grid>
            </Grid>
            <BobTable
              data_for_table={data_for_table}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              bob_records_count={bob_records_count}
              setCurrentPageSize={setCurrentPageSize}
              setTriggerFetch={setTriggerFetch}
              setActivePanel={setActivePanel}
              activePanel={activePanel}
              formData={formData}
              carriersOptions={carriersOptions}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

DashboardCustomers.propTypes = {
  setLocalLoader: PropTypes.func,
  getAppointmentsViewData: PropTypes.func,
  addRequestedAppointments: PropTypes.func,
  carriers: PropTypes.object,
  appointedCarriers: PropTypes.object,
  zohoUserData: PropTypes.object
};

export default DashboardCustomers;
