/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './CodeVerifier.module.scss';
import { VALID_KEYS } from './constants';

const CodeVerifier = props => {
  const [code, setCode] = useState([...Array(props.length)]);

  const handleKeyUpCapture = e => {
    e.preventDefault();
    try {
      const innterTerxtLength = e.currentTarget.innerText.length;
      // clear div
      const element = document.getElementById(`${e.currentTarget.id}`);
      const index = parseInt(e.currentTarget.id.split('_')[1]);
      const codeArray = code;
      const currentNumber =
        codeArray[index] === undefined ? '' : codeArray[index];
      if (element) {
        // check if key pressed is number
        if (VALID_KEYS.indexOf(e.key) != -1) {
          element.innerText = '';
          // if exists
          if (e.key === 'Backspace') {
            element.innerText = '';
            if (innterTerxtLength === 1) {
              codeArray[index] = undefined;
              setCode(code);
            }
            hancleCheckAllInserted(false);
            handleFocusPrevious(e.currentTarget.id);
          } else if (e.key === 'Enter') {
            codeArray[index] = currentNumber;
            element.innerText = currentNumber;
            if (index === props.length - 1) {
              hancleCheckAllInserted(true);
            } else {
              if (currentNumber) handleFocusNext(e.currentTarget.id);
            }
          } else {
            // add or replace number
            element.innerText = e.key;
            codeArray[index] = e.key;
            setCode(code);
            hancleCheckAllInserted(false);
            handleFocusNext(e.currentTarget.id);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hancleCheckAllInserted = submit => {
    const codeverified = code.filter(digit => isFinite(digit));
    if (codeverified.length === props.length) {
      if (props.getCode) props.getCode(codeverified.join(''));
      if (submit) if (props.submitCode) props.submitCode();
    } else {
      if (props.getCode) props.getCode(codeverified.join(''));
    }
  };

  const handleFocusNext = id => {
    const nextId = parseInt(id.split('_')[1]) + 1;
    const nextElement = document.getElementById(`cv_${nextId}`);
    nextElement?.focus();
    nextElement?.click();
  };

  const handleFocusPrevious = id => {
    const nextId = parseInt(id.split('_')[1]) - 1;
    const beforeElement = document.getElementById(`cv_${nextId}`);
    beforeElement?.focus();
    beforeElement?.click();
  };

  const renderItem = (item, key) => {
    return (
      <React.Fragment key={`cc_${key}`}>
        <div
          id={`cv_${key}`}
          key={`cv_${key}`}
          className={`${
            props.isLoading ? styles.codeElementDisabled : styles.codeElement
          }`}
          contentEditable={!props.isLoading}
          onCopy={() => {
            return false;
          }}
          onCut={() => {
            return false;
          }}
          onPaste={() => {
            return false;
          }}
          onKeyDownCapture={e => handleKeyUpCapture(e)}
        />
        {props.type === 'phone' && (key + 1 === 3 || key == 5) && (
          <p key={`key_${key}`}>-</p>
        )}
      </React.Fragment>
    );
  };
  return (
    <div className={styles.codeContainer}>
      {[...Array(props.length)].map(renderItem)}
    </div>
  );
};

export default CodeVerifier;
