import React from 'react';
import style from './Card.module.scss';

import propTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import LoadingContent from 'components/Loader/LoadingContante/LoadingContent';

const CardTypes = {
  primaryDarkened: style.primaryDarkened,
  primaryLight: style.primaryLight,
  primaryNavy: style.primaryNavy,
  default: style.default
};

const TitleTypes = {
  primary: style.primaryTitle,
  default: ''
};

function Card({
  description,
  title,
  titleType = 'default',
  children,
  full,
  fill,
  cardValues,
  container,
  adjust,
  withValue,
  value,
  titleOutside = false,
  type = 'default',
  icon,
  leftOptions,
  options,
  loading,
  onScrollCapture,
  middleSpace = true,
  titleOptionsSide,
  withHr,
  noPadding,
  showScrollbar = false
}) {
  return (
    <div
      onScrollCapture={onScrollCapture ? onScrollCapture : () => {}}
      className={`${style.cardContainer} ${container && style.container}`}
    >
      {titleOutside && title && (
        <div className={`${style.title} ${TitleTypes[titleType]}`}>
          <span>{title}</span>
          <div className={style.options}>{options}</div>
        </div>
      )}
      <div
        className={`${style.card} ${showScrollbar && style.showScrollbar} ${
          CardTypes[type] ? CardTypes[type] : CardTypes['default']
        } ${full ? style.cardFull : ''} ${fill ? style.cardFill : ''} ${
          titleOutside ? style.titleOutside : ''
        } ${cardValues ? style.cardValues : ''}`}
        style={{
          padding: noPadding ? '20px 10px 20px 10px' : '20px 35px'
        }}
      >
        <div className={`${style.leftContent} ${adjust ? style.adjust : ''}`}>
          <div>
            {!titleOutside && title && (
              <div>
                {withHr ? (
                  <div
                    className={`${style.subtitleWithHR} ${
                      TitleTypes[titleType]
                    } ${!middleSpace ? style.slenderSubtitle : ''}`}
                  >
                    <div>
                      <div className={style.titleOptions}>
                        {leftOptions && (
                          <div className={style.leftOptions}>{leftOptions}</div>
                        )}
                        <span>{title}</span>
                      </div>
                    </div>
                    {withHr ? <hr className={style.withHr} /> : null}
                  </div>
                ) : (
                  <div
                    className={`${style.subtitle} ${TitleTypes[titleType]} ${
                      !middleSpace ? style.slenderSubtitle : ''
                    }`}
                  >
                    <div>
                      <div className={style.titleOptions}>
                        {leftOptions && (
                          <div className={style.leftOptions}>{leftOptions}</div>
                        )}
                        <span>{title}</span>
                      </div>
                      {titleOptionsSide && (
                        <div className={style.titleOptionsSide}>
                          {titleOptionsSide}
                        </div>
                      )}
                      <div className={style.options}>{options}</div>
                    </div>
                    {middleSpace ? <hr /> : null}
                  </div>
                )}
              </div>
            )}
            {!withValue && description && (
              <div className={style.description}>
                <p>{description}</p>
              </div>
            )}
            {withValue && description && (
              <div className={style.description}>
                {loading ? (
                  <CircularProgress size={30} color={'inherit'} />
                ) : (
                  <span className={style.number}>{value}</span>
                )}
                <p>{description}</p>
              </div>
            )}
          </div>
          {!withValue && loading ? <LoadingContent /> : <>{children}</>}
        </div>
        {icon && <div className={style.icon}>{icon}</div>}
      </div>
    </div>
  );
}

Card.propTypes = {
  description: propTypes.string,
  title: propTypes.string,
  titleType: propTypes.string,
  full: propTypes.bool,
  fill: propTypes.bool,
  cardValues: propTypes.bool,
  container: propTypes.bool,
  adjust: propTypes.bool,
  withValue: propTypes.bool,
  value: propTypes.number,
  titleOutside: propTypes.bool,
  type: propTypes.string,
  icon: propTypes.element,
  leftOptions: propTypes.node,
  options: propTypes.element,
  loading: propTypes.bool,
  onScrollCapture: propTypes.func,
  middleSpace: propTypes.bool,
  titleOptionsSide: propTypes.string,
  children: propTypes.node,
  withHr: propTypes.bool,
  noPadding: propTypes.bool,
  showScrollbar: propTypes.bool
};

export default Card;
