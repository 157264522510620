import React, { useState } from 'react';
import Login from './Login';
import PhoneNumberInput from 'views/MFA/PhoneNumberInput/PhoneNumberInput';
import VerificationCode from 'views/MFA/CodeVerification/VerificationCode';

import firebase from 'firebase/compat/app';

import axios from 'axios';

function LoginMFA() {
  const [resolver, setResolver] = useState(null);
  const [userCredentials, setUserCredentials] = useState(null);
  const [verificationId, setVerificationId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  if (!resolver && !userCredentials) {
    return (
      <Login
        setResolver={setResolver}
        setUserCredentials={setUserCredentials}
      />
    );
  }

  if (verificationId === '') {
    const fixEmailVerification = async () => {
      const userId = firebase.auth().currentUser?.uid;
      await axios.post(
        'https://us-central1-o-neill-marketing-portal.cloudfunctions.net/forceEmailVerify',
        { uid: userId }
      );
    };

    fixEmailVerification();

    return (
      <PhoneNumberInput
        resolver={resolver}
        setVerificationId={setVerificationId}
        setPhoneNumber={setPhoneNumber}
      />
    );
  }
  return (
    <VerificationCode
      resolver={resolver}
      userCredentials={userCredentials}
      verificationId={verificationId}
      setVerificationId={setVerificationId}
      phoneNumber={phoneNumber}
      setUserCredentials={setUserCredentials}
      reloadPortal
    />
  );

  // return !resolver && !userCredentials ? (
  //   <Login setResolver={setResolver} setUserCredentials={setUserCredentials} />
  // ) :

  // verificationId === '' ? (
  //   <PhoneNumberInput
  //     resolver={resolver}
  //     setVerificationId={setVerificationId}
  //     setPhoneNumber={setPhoneNumber}
  //   />
  // ) : (
  //   <VerificationCode
  //     resolver={resolver}
  //     userCredentials={userCredentials}
  //     verificationId={verificationId}
  //     phoneNumber={phoneNumber}
  //     setUserCredentials={setUserCredentials}
  //     reloadPortal
  //   />
  // );
}

export default LoginMFA;
