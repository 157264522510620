import {
  CLEAN_APPOINTMENTS,
  CLEAN_BONUS,
  CLEAN_COMMISSIONS,
  CLEAN_DASHBOARD,
  CLEAN_LEADS,
  CLEAN_MARKETING_ANNOUNCEMENTS,
  CLEAN_NOTIFICATIONS,
  CLEAN_SITE,
  CLEAN_USER,
  LOG_OUT,
  IS_LOGGING_IN,
} from 'constants/types';
import firebase from 'firebase/compat/app';
import { siteLoaded, siteLoading } from './site';

import { getUser } from './user';

export const setIsLoggingIn = payload => {
  return {
    type: IS_LOGGING_IN,
    payload
  };
};

export const LogIn = (userData, setUserData) => {
  return async dispatch => {
    console.log('LogIn');
    console.log(userData);
    dispatch(setIsLoggingIn(true));
    firebase
      .auth()
      .signInWithEmailAndPassword(userData.email, userData.password)
      .then(user => {
        console.log('/---/');
        console.log(user);
        dispatch(getUser(user, userData, setUserData));
        dispatch(setIsLoggingIn(false));
        dispatch(siteLoading());
      })
      .catch(error => {
        console.log('login error');
        console.log(error);
        if (error.code === 'auth/wrong-password')
          setUserData({
            ...userData,
            errors: { ...userData.errors, password: 'Wrong password' }
          });
        else if (error.code === 'auth/user-not-found')
          setUserData({
            ...userData,
            errors: {
              ...userData.errors,
              email: 'No user found with this e-mail'
            }
          });
        // else if (error.code === 'auth/multi-factor-auth-required') {
        //   const resolver = error.resolver;
        // }
        // else if (error.code === 'auth/multi-factor-auth-required') {
        //   const resolver = error.resolver;
        // }
        else console.log(error);
        dispatch(setIsLoggingIn(false));
      });
  };
};

export const LogOut = () => async dispatch => {
  dispatch(siteLoading());
  await firebase.auth().signOut();
  dispatch({ type: LOG_OUT });
  dispatch({ type: CLEAN_USER });
  dispatch({ type: CLEAN_DASHBOARD });
  dispatch({ type: CLEAN_APPOINTMENTS });
  dispatch({ type: CLEAN_BONUS });
  dispatch({ type: CLEAN_COMMISSIONS });
  dispatch({ type: CLEAN_LEADS });
  dispatch({ type: CLEAN_MARKETING_ANNOUNCEMENTS });
  dispatch({ type: CLEAN_NOTIFICATIONS });
  dispatch({ type: CLEAN_SITE });
  dispatch(siteLoaded());
};
