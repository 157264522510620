import endpoints from 'constants/endpoints';
import { transferTypes } from 'constants/enum';
import {
  GET_APPOINTED_CARRIERS,
  GET_CARRIERS,
  SET_CARRIERS_ARRAY,
  SET_STATEMENTS_CARRIERS_ARRAY,
  GET_REQUESTED_APPOINTMENTS,
  IS_SENDING_REQUEST_APPOINTMENTS,
  IS_UPDATING_REQUEST_APPOINTMENTS,
  GET_STATEMENT_PERIODS,
  SET_REQUESTED_CARRIER_NAME,
  UPDATE_LOADING_CARD_STATE
} from 'constants/types';
import {
  appointmentsObjectFactory,
  mergeRepeatedCarriers
} from 'helpers/factory/appointmentFactory';
import {
  appointedCarriersObjectFactory,
  carrierFactory,
  carriersObjectFactory
} from 'helpers/factory/carrierFactory';
import { safePostCall, safeAWSCallGet } from 'helpers/firebaseSetup';
import { refreshUser } from './actions';
import { showAlert, showAlertModal } from './site';

// action creators

export const setRequestedCarrierName = payload => ({
  type: SET_REQUESTED_CARRIER_NAME,
  payload
});

const setIsSendingRequestappointments = payload => {
  return {
    type: IS_SENDING_REQUEST_APPOINTMENTS,
    payload
  };
};
const setIsUpdatingRequestAppointments = payload => {
  return {
    type: IS_UPDATING_REQUEST_APPOINTMENTS,
    payload
  };
};

export const getAppointmentsViewData =
  (setLocalLoader = () => {}, cancelTokenSource = {}, globalload = false) =>
  async (dispatch, getState) => {
    //Only if the page is refreshed
    // if (!currentCarriers) {
    if (globalload === true) {
      setLocalLoader(true);
    } else {
      dispatch({
        type: UPDATE_LOADING_CARD_STATE,
        payload: true
      });
      setLocalLoader(false);
    }
    let zohoId = getState().user.zohoId;

    //Refreshes the user that's been pulled from zoho in case something changed
    await dispatch(refreshUser());
    // console.log();
    safePostCall(
      endpoints.awsProxy.safePost,
      {
        zohoId,
        endpoint: endpoints.zoho.getCarriersAndAppointments
      },
      { cancelToken: cancelTokenSource.token }
    )
      .then(carriersRes => {
        if (carriersRes) {
          let zohoData = getState().user.zohoData;
          //Formats the response
          let response = JSON.parse(carriersRes.data.details.output);
          //Gets the carriers and formats them
          let carriers = [...response.Carriers];
          // Gets a copy of the carriers array exclusive for the Statements tab
          let statementsCarriers = [...response.Carriers];
          // filter depending on the form preferences
          // console.log(zohoData.agencyData);
          if (zohoData.agencyData.Hide_Off_Market === true) {
            carriers = carriers.filter(
              carrier => carrier.Appointment_Type !== 'Off-Market Health'
            );
          }
          if (zohoData.agencyData.Hide_Medicare === true) {
            carriers = carriers.filter(
              carrier => carrier.Appointment_Type !== 'Medicare'
            );
          }
          if (zohoData.agencyData.Hide_Life === true) {
            carriers = carriers.filter(
              carrier => carrier.Appointment_Type !== 'Life'
            );
          }
          // create factory of carriers and filter depending on the preferences
          carriers = carriers
            .map(carrier => carrierFactory(carrier, zohoData))
            .filter(carrier => carrier.exclude === false);
          statementsCarriers = statementsCarriers.map(carrier =>
            carrierFactory(carrier, zohoData)
          );
          //Builds the carriersObj for redux
          let carriersObj = carriersObjectFactory(carriers);
          //Sends payload to set carriers redux state
          dispatch({ type: GET_CARRIERS, payload: carriersObj });
          dispatch({ type: SET_CARRIERS_ARRAY, payload: carriers });
          dispatch({
            type: SET_STATEMENTS_CARRIERS_ARRAY,
            payload: statementsCarriers
          });
          //Builds the requestedAppointmentsObj for redux
          //Adds the "In Progress" array to each requested appointment
          let requestedAppointmentsObj = appointmentsObjectFactory(
            carriersObj,
            mergeRepeatedCarriers(response.Requested_Appointments),
            zohoData
          );
          //Sends payload to set requested appointments redux state
          dispatch({
            type: GET_REQUESTED_APPOINTMENTS,
            payload: {
              requestedAppointments: requestedAppointmentsObj,
              requestedAppointmentsArray: response.Requested_Appointments
            }
          });
          dispatch({
            type: GET_APPOINTED_CARRIERS,
            payload: appointedCarriersObjectFactory(carriersObj, zohoData)
          });
        }
        setLocalLoader(false);
        dispatch({
          type: UPDATE_LOADING_CARD_STATE,
          payload: false
        });
      })
      .catch(error => {
        console.log(error);
        if (!(error?.message === 'API Call Canceled')) setLocalLoader(false);
      });
    // } else {
    //   setLocalLoader(false);
    // }
  };

export const getStatementsPeriods =
  (setLocalLoader = () => {}, cancelTokenSource = {}) =>
  async (dispatch, getState) => {
    safeAWSCallGet(endpoints.aws.comissionsPeriods, {}, false)
      .then(periodsRes => {
        if (periodsRes) {
          dispatch({
            type: GET_STATEMENT_PERIODS,
            payload: periodsRes.data.map(p => ({ name: p, value: p }))
          });
        }
        setLocalLoader(false);
      })
      .catch(error => {
        console.log(error);
        if (!(error?.message === 'API Call Canceled')) setLocalLoader(false);
      });
  };

export const addRequestedAppointments =
  (cancelTokenSource = {}, data, onSuccess) =>
  async (dispatch, getState) => {
    dispatch(setIsSendingRequestappointments(true));
    const agent = getState().user;

    const formData = {
      AgentZohoID: agent.zohoId,
      email: agent.zohoData.Email,
      oneillEmail: agent.zohoData.Oneill_Email,
      NPN: agent.zohoData.NPN,
      states: data.states,
      hasStates: data.hasStates,
      transfer: data.transfer,
      carrierName: data.carrierName,
      endpoint: endpoints.zoho.addRequestedAppointments
    };

    dispatch(
      showAlert({
        message: 'Sending request',
        code: 'info'
      })
    );

    await safePostCall(endpoints.awsProxy.safePost, formData, {
      cancelToken: cancelTokenSource.token
    })
      .then(async () => {
        await dispatch(getAppointmentsViewData());
        const title = 'Request sent successfully! Appointment instructions:';
        dispatch(
          showAlert({
            message: 'Request sent successfully!',
            code: 'success'
          })
        );

        onSuccess && onSuccess();

        if (data.carrierData.primaryCarrier && agent.zohoData.Oneill_Email) {
          dispatch(
            showAlertModal({
              title,
              message:
                'Appointment information will be sent to your O’Neill Insurance email account. Our team will work to complete the contracting for you.',
              code: 'success'
            })
          );
        } else {
          if (data.transfer === transferTypes.DirectWithCarrier) {
            dispatch(
              showAlertModal({
                title,
                message: data.carrierData.customNoteTransferDirectwithCarrier
              })
            );
          } else if (data.transfer === transferTypes.ThroughAnotherAgency) {
            dispatch(
              showAlertModal({
                title,
                message: data.carrierData.customNoteTransferThroughAnotherAgency
              })
            );
          } else if (data.transfer === transferTypes.NewAppointment) {
            dispatch(
              showAlertModal({
                title,
                message: data.carrierData.customNoteNewAppointment
              })
            );
          }
        }

        dispatch(setIsSendingRequestappointments(false));
      })
      .catch(error => {
        console.log(error);
        dispatch(
          showAlert({
            message: 'Something went wrong. Try again later.',
            code: 'error'
          })
        );
        dispatch(setIsSendingRequestappointments(false));
      });
  };

export const updateSpecificAppointmentStatus =
  (agentStatus, carrier) => async (dispatch, getState) => {
    dispatch(setIsUpdatingRequestAppointments(true));
    const requestedAppointmentsArray =
      getState().appointments.requestedAppointmentsArray;

    const filteredRequests = requestedAppointmentsArray.filter(
      request => request.carrier === carrier
    );
    dispatch(showAlert({ message: 'Updating request status', code: 'info' }));
    filteredRequests.map(async (request, index) => {
      await safePostCall(endpoints.awsProxy.safePost, {
        appointmentRequestID: request.id,
        agentStatus,
        endpoint: endpoints.zoho.updateSpecificAppointmentStatus
      })
        .then(async () => {
          if (filteredRequests.length === index + 1) {
            await dispatch(getAppointmentsViewData());
            dispatch(
              showAlert({
                message: 'Updated successfully!',
                code: 'success'
              })
            );
            dispatch(setIsUpdatingRequestAppointments(false));
          }
        })
        .catch(() => {
          dispatch(
            showAlert({
              message: 'Something went wrong. Try again later.',
              code: 'error'
            })
          );
          dispatch(setIsUpdatingRequestAppointments(false));
        });
    });
  };
