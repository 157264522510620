import { useState } from 'react';
import firebase from 'firebase/compat/app';

function useChangePassword() {

  const [errors, setErrors] = useState({
    newPassword: '',
    repeatNewPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [needsReauthentication, setNeedsReauthentication] = useState(false);

  const handleSetNewPassword = value => {
    setNewPassword(value);
    setErrors({ ...errors, newPassword: '' });
  };

  const handleSetRepeatNewPassword = value => {
    setRepeatNewPassword(value);
    setErrors({ ...errors, repeatNewPassword: '' });
  };

  async function changePassword() {
    setErrors({
      newPassword: '',
      repeatNewPassword: '',
    });
    setLoading(true);
    try {
      if (newPassword !== repeatNewPassword) {
        setErrors({
          ...errors,
          repeatNewPassword: 'Passwords do not match',
        });
      } else {
        const user = firebase.auth().currentUser;
        await user.updatePassword(newPassword);
        setPasswordChanged(true);
      }
    } catch(e){
      console.log(e);
      if (e.code === 'auth/weak-password') {
        setErrors({
          ...errors,
          newPassword: 'Password is too weak',
        });
      }
      else if (e.code === 'auth/requires-recent-login') {
        setNeedsReauthentication(true);
      }
      else {
        setErrors({
          ...errors,
          newPassword: 'There was an error while changing your password',
        });
      }
    }
    setLoading(false);
  }

  return {
    errors,
    loading,
    passwordChanged,
    newPassword,
    repeatNewPassword,
    setNewPassword: handleSetNewPassword,
    setRepeatNewPassword: handleSetRepeatNewPassword,
    changePassword,
    needsReauthentication,
  };
  
}

export default useChangePassword;