import {
  UPDATE_AGENT_POLIICES_ARRAY,
  UPDATE_AGENT_POLICIES_COUNT
} from 'constants/types';

const INITIAL_STATE = {
  policies: [],
  agent_policies_count: 0
};

function healthSherpaDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_AGENT_POLIICES_ARRAY:
      console.log('got here');
      console.log(action.payload);
      return {
        ...state,
        policies: action.payload
      };
    case UPDATE_AGENT_POLICIES_COUNT:
      return {
        ...state,
        agent_policies_count: action.payload
      };
    default:
      return state;
  }
}

export default healthSherpaDataReducer;
